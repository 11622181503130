<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="editPanelPropChange">
        <div class="back">
            <a href="#" class="back-icon">
                <span class="inch-icon inch-back-icon"></span>
            </a>

            <h3>Face Restoration</h3>
        </div>

        <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
            <img :src="helpIcon" alt/>
        </div>
        </div>

        <div v-bar style="height: 568px;">
        <div class="text_panel_scroll">
            <div class="panel_row">
                <a href="#" class="blue_btn full_w_btn" @click="applyIt">
                    Restore Face
                </a>
            </div>

            <div class="panel_row">
                <Range
                    rangeSliderId="codeformer_fidelity"
                    :rangeSliderValue="payload.codeformer_fidelity"
                    rangeSliderLabel="Codeformer Fidelity"
                    rangeSliderClass="range-blue"
                    :minValue=".1" :stepSlider=".01"
                    :maxValue="1" :defaultValue="payload.codeformer_fidelity"
                    @dragEnd="changeRange('codeformer_fidelity')($event)"
                    :changeValue="changeRange('codeformer_fidelity')"
                />
                <span class="custom-label-text">Balance the quality and fidelity</span>
            </div>

            <div class="panel_row margin_bottom_15">
                <ToggleCheck
                    toggleCheckLabel="Background Enhance"
                    :thisToggleCheckChecked="payload.background_enhance"
                    @toggleCheckHandle="checkToggle('backgroundEnhance', $event)"
                />
            </div>
            
            <div class="panel_row margin_bottom_15">
                <ToggleCheck
                    toggleCheckLabel="Face Upsample"
                    :thisToggleCheckChecked="payload.face_upsample"
                    @toggleCheckHandle="checkToggle('faceUpsample', $event)"
                />
            </div>

            <div class="panel_row margin_bottom_15">
                <label class="text_label has_mb">Upscale</label>
                <div class="btns_wrpr">
                    <div class="input_width_height_wrpr">
                        <InputWidthHeight
                            :isChangeInputValueIncrement="true"
                            :inputValue="payload.upscale"
                            :maxValue="5" :minValue="1"
                            @change="changeRange('upscale')($event.value)"
                            inputName="upscale"
                        />
                    </div>

                    <span class="custom-label-text">The final upsampling scale of the image</span>
                </div>
            </div>

            <!-- <div class="panel_row">
                <a tabIndex="0" class="blue_btn full_w_btn" v-on:click="applyIt">Processing</a>
            </div> -->
        </div>

        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>

        <TooltipAI
            :list="['Enhance and restore details in facial images for a clearer, more refined look.']"
            filename="Face_Restoration"
        />
    </div>
</template>

<script>
  import BaseComponent from './BaseComponent.js';

  export default {
    name: "FaceRestoration",
    mixins: [BaseComponent],
    data: function() {  
      return {
        payload: {
            background_enhance: true,
            face_upsample: true,
            codeformer_fidelity: .1,
            upscale: 2
        }
      };
    },

    computed: {
        toolApi() {
            return this.$aitool.tools.FaceRestorationAPI;
        },
        isPremium() {
            return this.$store.state.isPremiumAccount;
        },
    },
  
    methods: { 
        upgradePlan() {
            this.$store.dispatch("popupsToggle", {
                property: "stripeCheckoutPopup",
                value: true,
            });
        },
        async validateFaces(){
            let blob = await this.$aitool.getCurrentBlobImage();
            let b64 = await this.blobToB64(blob);
            let faces = await this.detectFaces(b64);
            if(faces==0){
                return {success: false, error: "No faces detected. Please upload an image that contains at least one human face."};
            }
            return {success: true};
        }
    
    }
  };
</script>

<style scoped>
    .blue-color-text{
        color: #249ACF;
    }
</style>
