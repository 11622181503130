<template>
  <div>
    <div v-if="appendClass == 'enabled' && !isMobileView" class="left_sidebar_wrpr" :class="appendClass">
      <aside class="left_sidebar">
        <div class="tools-panel">
          <div
                  v-for="(item, i) in sidebarItems"
                  v-bind:key="'si_' + i + '_' + item.id"
                  class="tool-item"
                  :class="item.class"
                  v-click-outside.prevent="(item.class == 'setting' ? closeOutside : '')"
          >
            <a
                    class="tool-link"
                    :class="isCurrent(item.linkText)"
                    v-on:click.stop="toolLinkClick(item.linkText)"
                    @keyup.space="toolLinkClick(item.linkText)"
                    @keyup.enter="toolLinkClick(item.linkText)"
                    :tabIndex=item.tabIndex
            >
            <div  v-if="item.linkText === 'AI Tools'">
              <div class="new-badge">
                <div class="new-badge-content">
                  <sup>AI</sup>
                </div>
              </div>
            </div>
              <div class="icon_wrpr">
                <span class="inch-icon" :class="item.icon" v-if="!item.isStrokeSvg"></span>
                <span class="inch-icon" v-else v-html="item.svg"></span>
              </div>
              <span class="tool_link_text">{{item.linkText}}</span>
            </a>
          </div>
        </div>
      </aside>

      <ImagePanel
              v-if="(sidebarPanelOpened === 'Image Manager' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <EditPanel
              v-if="(sidebarPanelOpened === 'Edit' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      
      <AIToolPanel v-if="(sidebarPanelOpened === 'AI Tools' && showPanel)"
          :sidebarPanelClass="sidebarPanelClass"
      />
      
      <EffectsPanel
              v-if="(sidebarPanelOpened === 'Effects' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      
      <FiltersPanel
              v-if="(sidebarPanelOpened === 'Filters' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <OverlaysPanel
              v-if="(sidebarPanelOpened === 'Overlays' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <MasksPanel
              v-if="(sidebarPanelOpened === 'Masks' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <TextPanel
              v-if="(sidebarPanelOpened === 'Text' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <DrawPanel
              v-if="(sidebarPanelOpened === 'Draw' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <GraphicsPanel
              v-if="(sidebarPanelOpened === 'Graphics' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <FramesPanel
              v-if="(sidebarPanelOpened === 'Frames' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
      />
      <Settings v-if="sidebarSettings === 'show'" v-click-outside="closeOutside"/>
    </div>
    
    <div v-if="appendClass == 'enabled' && isMobileView" :class="appendClass">
      <!--      <aside class="left_sidebar">-->
      <div v-if="showNavbar" class="tools-panel-navbar hide_scroll">
        <div
                v-for="item in sidebarItemsForMobileView"
                v-bind:key="'sh_' + item.id"
                :class="item.class"
                v-click-outside.prevent="(item.class == 'setting' ? closeOutside : '')"
        >
          <a
                  :class="isCurrent(item.linkText)"
                  v-on:click.stop="toolLinkClick(item.linkText)"
                  @keyup.space="toolLinkClick(item.linkText)"
                  @keyup.enter="toolLinkClick(item.linkText)"
                  :tabIndex=item.tabIndex
          >
            <span
                    class="link-title"
                    :class="{active: isCurrent(item.linkText)}"
            >
              {{item.linkText}}</span>
          </a>
        </div>
      </div>
      <!--      </aside>-->
      <div class="trans-panel hide_scroll">
        <transition name="slide-fade">

          <EffectsPanel
              v-if="(sidebarPanelOpened === 'Effects' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
          />

          <FiltersPanel
              v-if="(sidebarPanelOpened === 'Filters' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
          />


          <OverlaysPanel
              v-if="(sidebarPanelOpened === 'Overlays' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
          />
          <MasksPanel
              v-if="(sidebarPanelOpened === 'Masks' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
          />
          <FramesPanel
              v-if="(sidebarPanelOpened === 'Frames' && showPanel)"
              :sidebarPanelClass="sidebarPanelClass"
          />
        </transition>
      </div>


    </div>
  </div>

</template>

<script>
  import ImagePanel from "@/components/sidebarPanel/imagePanel/ImagePanel.vue";
  import AIToolPanel from "@/components/sidebarPanel/AIToolPanel/AIToolPanel.vue";
  import EditPanel from "@/components/sidebarPanel/editPanel/EditPanel.vue";
  import EffectsPanel from "@/components/sidebarPanel/effectsPanel/EffectsPanel.vue";
  import FiltersPanel from "@/components/sidebarPanel/filtersPanel/FiltersPanel.vue";
  import OverlaysPanel from "@/components/sidebarPanel/overlaysPanel/OverlaysPanel.vue";
  import MasksPanel from "@/components/sidebarPanel/masksPanel/MasksPanel.vue";
  import TextPanel from "@/components/sidebarPanel/textPanel/TextPanel.vue";
  import DrawPanel from "@/components/sidebarPanel/drawPanel/DrawPanel.vue";
  import GraphicsPanel from "@/components/sidebarPanel/graphicsPanel/GraphicsPanel.vue";
  import FramesPanel from "@/components/sidebarPanel/framesPanel/FramesPanel.vue";
  import Settings from "@/components/sidebarPanel/settings/Settings.vue";
  const analitics = require('../libs/analytics');

  export default {
    name: "Sidebar",
    components: {
      ImagePanel,
      EditPanel,
      EffectsPanel,
      FiltersPanel,
      OverlaysPanel,
      MasksPanel,
      TextPanel,
      DrawPanel,
      GraphicsPanel,
      FramesPanel,
      Settings, AIToolPanel
    },
    props: {
      sidebarItems: Array,
      appendClass: String
    },
    data: function() {
      return {
        isMobileView: false,
        showNavbar: true,
        currentPanel: "",
        showPanel: true,
        sidebarItemsForMobileView: [
          {
            id: 1,
            icon: "inch-effects-icon",
            linkText: "Effects",
            tabIndex: 1,
          },
          {
            id: 2,
            icon: "inch-filters-icon",
            linkText: "Filters",
            tabIndex: 2,
          },
          {
            id: 3,
            icon: "inch-overlays-icon",
            linkText: "Overlays",
            tabIndex: 3,
          },
          {
            id: 4,
            icon: "inch-masks-icon",
            linkText: "Masks",
            tabIndex: 4,
          },
          {
            id: 5,
            icon: "inch-frames-icon",
            linkText: "Frames",
            tabIndex: 5,
          },
        ],
      };
    },
    computed: {
      sidebarPanelOpened() {
        return this.$store.state.sidebarPanel.panelName;
      },
      sidebarPanelClass() {
        return this.$store.state.sidebarPanel.panelClass;
      },
      sidebarSettings: {
        get() { return this.$store.state.sidebarPanel.panelSettings; },
        set(val) { this.$store.state.sidebarPanel.panelSettings = val; }
      },

    },

    watch: {
      sidebarPanelOpened(sidebarName) {
        analitics.sendEvent(sidebarName, analitics.categ.openTab);
      }
    },

    mounted() {
      this.isMobileView = this.$store.state.isMobileView;
    },

    methods: {
      closeOutside() {
        this.sidebarSettings = false;
      },

      toolLinkClick(panelName) {
        if(window.is_integration){
          window.apiUsage({});
        }
        this.showPanel = true;
        if (panelName === "Settings") {
          this.openSettings();
        }
                // else if(this.currentPanel === panelName) {
                //     this.closePanel();
        // }
        else {
          this.openPanel(panelName);
        }
      },
      openPanel(panelName) {
        this.$lib.Drawing.initDraw(false);

        this.$store.dispatch("sidebarPanelToggle", {
          panelName,
          panelClass: "active"
        });
        this.currentPanel = panelName;
      },
      closePanel() {
        this.$lib.Drawing.initDraw(false);

        this.$store.dispatch("sidebarPanelToggle", {
          panelName: "",
          panelClass: ""
        });
        this.currentPanel = "";
        this.showPanel = false;
      },
      openSettings() {
        this.$store.dispatch("sidebarPanelToggle", { panelSettings: "show" });
      },

      isCurrent(value) {
        if(this.currentPanel != this.$store.state.sidebarPanel.panelName) {
          this.currentPanel = this.$store.state.sidebarPanel.panelName;
        }

        return value === this.currentPanel ? "active" : "";
      }
    }
  };
</script>

<style scoped>

    .new-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
      overflow: hidden;
      border-radius: 9999px;
      padding: 0.125em;
    }
    .new-badge-content {
      width: 11px;
      height: 11px;
      text-align: center;
      font-style: normal;
      font-variant: normal;
      font-weight: bold;
      font-size: 11px;
      font-family: Proxima Nova;
      letter-spacing: 0.32px;
      color: #4ADABA;
      opacity: 1;
    }

  .tool-item .tool-link:not(.active):hover .tool_link_text {
    left: calc(100% - 10px);
    -webkit-box-shadow: 1px 1px 2px #1d1d1db3;
    box-shadow: 1px 1px 2px #1d1d1db3;
    padding-left: 20px;
    margin-left: 10px;
    position: absolute;
    left: 100%;

    padding: 15px 20px 16px 20px;
    height: auto;
    top: 8px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #1D1D1DB3;
    color: #FFFFFF;
    background: #353535;
    font: normal normal normal 14px/17px Proxima Nova;
    letter-spacing: 0px;
    animation-duration: .3s;
    animation-name: slidein;
  }

  @keyframes slidein {
    0% {
      left: calc(100% - 4px);
    }

    100% {
      left: 100%;
    }
  }

  .tool-item .tool-link .tool_link_text {
    -webkit-transition: none;
    transition: none;
  }

  .tool-item .tool_link_text:before {
    content: '';
    display: block;
    position: absolute;
    left: -12px;
    top: 50%;
    border: 5px solid transparent;
    border: 6px solid transparent;
    border-right: 8px solid #353535;
    margin-top: -6px;
  }
  .tools-panel-navbar {
    background: #2A2A2A;
    border-top: 1px solid #303030;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 45.5px;
    padding: 12px 6px 12px 20px;
    z-index: 100;
    position: fixed;
    bottom: 0;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: transparent transparent;
  }
  .tools-panel-navbar > div {
    flex: 0 0 auto;
    height: 21px;
    margin-right: 19px;
  }

  .link-title {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 100;
    text-align: center;
    line-height: 13px;
    letter-spacing: -0.44px;
    padding: 4px 10px;
  }
  .link-title.active {
    color: #000000;
    background: #FFFFFF;
    font-weight: 700;
    border-radius: 3px;
  }
  .trans-panel {
    position: fixed;
    bottom: 45.5px;
    z-index: 100;
    background: #2A2A2A;
    max-height: 111px;
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: transparent transparent;
  }
  .slide-fade-enter-active {
    transition: all .4s ease-in;
  }
  .slide-fade-leave-active {
    transition: all .3s ease-out;
  }
  .slide-fade-enter {
    transform: translateY(120px);
    opacity: 0;
  }
  .slide-fade-leave-to {
    transform: translateY(120px);
    opacity: 0;
  }

</style>
