<template>
  <div :class="[{'tool-item-panel': !isMobileView}, sidebarPanelClass]">
    <div v-if="!isMobileView" class="panel-top without_hover">
      <div class="back">
        <h3>filters</h3>
      </div>
    </div>
    <div :class="{'panel-content': !isMobileView, 'wo_pad': !isMobileView, 'wo_pad_wo_overfl': !isMobileView}">
      <div v-if="!isMobileView" v-bar>
        <div class="panel_row effects_items_wrpr effects_categories_wrpr">
          <Favorites
            v-if="favoritesList.length"
            :favoritesCount="favoritesList.length"
            :favoritesOpen="favoritesOpen"
          />
          <template v-for="(cardItem, index) in panels">
            <CategoryCard
              v-bind:key="index"
              :toolLinkClick="toolLinkClick"
              :msg="cardItem.title"
              :imgPath="cardItem.imgPath || cardItem.url"
            />
          </template>
          <AdLeftPanel v-if="isLeftAd" margin="20px 0px" marginLeft="0" />
        </div>

      </div>
      <transition name="panel-slide">
        <div v-if="isMobileView && showPanel" class="panel_row_mobile">
          <template v-for="(cardItem, index) in panels">
            <CategoryCard
                :isMobileView="isMobileView"
                v-bind:key="index"
                :toolLinkClick="toolLinkClick"
                :msg="cardItem.title"
                :imgPath="cardItem.imgPath || cardItem.url_m || cardItem.url"
            />
          </template>
        </div>
      </transition>
    </div>

    <transition name="panel-slide">
      <template v-for="(panel, index) in panels">
        <ThumbsPanel
          :appliedPresetId="appliedPresetId"
          v-bind:key="index"
          v-if="panelOpened === panel.title"
          :pannelInfo="pannelInfo"
          :panelName="panel.title"
          :panelItems="panel.panelItems"
          :panelPropChange="panelPropChange"
          :popUpPropChange="popUpPropChange"
          @panelView="panelView"
          :filterPreview="filterPreview"
          :isValuesPanel="true"
          :applyFilter="applyFilter"
          :panelClass="panelClass"
          :panelLoading="panelLoading"
          :favoritesPropChange="favoritesPropChange"
          :panelId="panel.id"
          :popUpVisible="popUpVisible"
          :sliderVal="filterConfig.valueAlpha"
          :sliderDefaultValue="filterConfig.defaultValueAlpha"
          :sliderMax="100"
          :sliderMin="1"
          :sliderUpdate="changeEffectAlpha"
          @loadSecondPageIfBigScreen="loadSecondPageIfBigScreen"
          @contentUpload="contentUpload"
          :isLoading="pageControl.isLoading"
        >
          <template v-slot:popup>
            <PopupFilters
              :eraseConfig="eraseData"
              @changeConfig="changeConfig"
              :popUpVisible="popUpVisible"
              :popUpLabel="popUpLabel"
              :popUpPropChange="popUpPropChange"
              :paramValues="getSelectedValues || []"
              :uniforms="getSelectedParams"
              :freezeSelectedParams="freezeSelectedParams"
              @updateUniformsFilter="updateUniformsFilter"
              @applyFilter="applyFilter"
              @resetFilterParams="resetFilterParams"
              :currentFilter="currentFilter"
              @selectPointerEvents="selectPointerEvents"
              @resetDrawing="resetDrawing(true, true)"
              :updateColorInverterOpacity="changeEffectAlpha"
            />
          </template>
        </ThumbsPanel>
      </template>
    </transition>

    <transition name="panel-slide">
      <ThumbsPanel
        :appliedPresetId="appliedPresetId"
        v-if="panelOpened === 'Favorites'"
        panelName="Favorites"
        :pannelInfo="pannelInfo"
        :isValuesPanel="true"
        :panelItems="favoritesList"
        :panelPropChange="panelPropChange"
        :popUpPropChange="popUpPropChange"
        :filterPreview="filterPreview"
        :applyFilter="applyFilter"
        :panelClass="panelClass"
        :panelLoading="panelLoading"
        :favoritesPropChange="favoritesPropChange"
        :sliderVal="filterConfig.valueAlpha"
        :sliderMax="100"
        :sliderMin="1"
        :sliderUpdate="changeEffectAlpha"
        :sliderDefaultValue="filterConfig.defaultValueAlpha"
      >
        <template v-slot:popup>
          <PopupFilters
            :eraseConfig="eraseData"
            :popUpVisible="popUpVisible"
            :popUpLabel="popUpLabel"
            :popUpPropChange="popUpPropChange"
            :paramValues="getSelectedValues || []"
            :uniforms="getSelectedParams"
            :freezeSelectedParams="freezeSelectedParams"
            @updateUniformsFilter="updateUniformsFilter"
            @applyFilter="applyFilter"
            @resetFilterParams="resetFilterParams"
            :currentFilter="currentFilter"
            @selectPointerEvents="selectPointerEvents"
            @changeConfig="changeConfig"
            @resetDrawing="resetDrawing(true, true)"
          />
        </template>
      </ThumbsPanel>
    </transition>
  </div>
</template>

<script>
import CategoryCard from "@/components/CategoryCard.vue";
import ThumbsPanel from "@/components/ThumbsPanel.vue";
import PopupFilters from "@/components/popupPanel/PopupFilters.vue";
import Favorites from "@/components/Favorites.vue";
import EarseToolMixin from '../../../mixin/EarseToolMixin';
import AdLeftPanel from "@/components/AdLeftPanel.vue";
const analitics = require("../../../libs/analytics");
import LoaderMixin from '../../../mixin/LoaderMixin';
import { EventBus } from '@/main.js';

export default {
  name: "FiltersPanel",
  props: {
    sidebarPanelClass: String,
    applyChanges: Function,
  },

  mixins: [EarseToolMixin, LoaderMixin],
  components: {
    CategoryCard,
    ThumbsPanel,
    Favorites,
    PopupFilters,
    AdLeftPanel,
  },

  data: function() {
    const defaultValueAlpha = 100;
    return {
      isMobileView: false,
      showPanel: true,
      appliedPresetId: false,
      lockPanelWatch: false,
      pannelInfo: {
        pannelTitle: "",
        pannelList: [
          { icon: "slider_icon", text: "Adjust the filter intensity" },
          { icon: "icon_heart", text: "Add selected filter to Favorites" },
          {
            icon: "icon_tune",
            text: "Access advanced settings to further fine-tune the filter",
          },
        ],
        pannelFooter: `<span class="inch-icon inch-plus-membership-icon"></span>Indicates the filter is a Pro feature. <a href='#get_plus'>Get Pro</a>`,
      },

      panelNameFavorite: "filters",
      filterConfig: {
        valueAlpha: defaultValueAlpha,
        defaultValueAlpha,
      },
      selectedParams: {},
      selectedValues: [],
      currentFilter: undefined,
      favoritesCount: 0,
      favoritesList: [],
      favoritesPanelVisible: false,
      popUpVisible: false,
      maskConfig: { },
      popUpLabel: "",
      panelLoading: true,
      panelOpened: "",
      panelClass: "",
      panels: [],
      panelsCache: [],
      isPremiumUser: false,
      params: {},
      pageControl: {
        currentPage: 1,
        totalPages: 1,
        isLoading: false,
      },
      selectedPanelId: null,
      freezeSelectedParams: {},
      maskTexture: false,
      beforeDestroyVar: false

    };
  },
  async created() {
    const result = await fetch(
      `${this.$store.state.prefixURL}/api/filters`
    ).then((response) => {
      return response.json();
    });

    this.panels = result;
  },

  async mounted() {
    this.isMobileView = this.$store.state.isMobileView;
    // $(document).on("tick_drawing", );

    this.updateGlobalEarseConfig();
    this.selectPointerEvents(true);

    this.changeConfig({ key: 'brushSize', value: this.eraseData.brushSize });
    // if (this.$store.state.USER) {
    //     try {
    //         this.$store.state.favorites.filters = await fetch(
    //             `/api/filters/favorites`
    //         ).then(res => res.json());
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
    //this.favoritesList = this.$store.state.favorites.overlays;
    /* await fetch("/api/filters/favorites").then(res =>
              res.json()
            ); */

    this.updateFavorites();
    this.beforeDestroyVar = false
  },

  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    zoom() {
      return this.$store.state.global.zoom;
    },

    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },

    getSelectedParams() {
      return this.selectedParams;
    },

    getFreezeSelectedParams() {
      return this.freezeSelectedParams;
    },

    getSelectedValues() {
      return this.selectedValues;
    },
  },

  watch: {
    panelOpened(val) {
      if(!val) {
        this.selectPointerEvents(true);
        // this.discardChanges();
        this.$lib.watermark.remove();

        // if(this.lockPanelWatch) {
        //   this.lockPanelWatch = false;
        //   return;
        // }

        //this.resetDrawing();
        this.discardChanges();
        this.$lib.Drawing.initDraw(false);

        // this.selectPointerEvents(true);
        //alert('H??');
      }
    },

    zoom() {
      if(this.$store.state.drawEventVisible) {
        this.$lib.Drawing.canvas.freeDrawingBrush.resizeTo(
          this.$lib.app.fabricCanvas.width,
          this.$lib.app.fabricCanvas.height
        );
      }
    },

    async _applySelectedEffect() {
      try {
        if (this.appliedPresetId && this.panelOpened) {
          await this.applyFilter();
        }
        if (this.$store.state.saveOnMobile) {
          EventBus.$emit("save-on-mobile");
        }
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },

  methods: {
    //TODO
    async loadSecondPageIfBigScreen(){
      if(!this.pageControl.isLoading && this.pageControl.currentPage < this.pageControl.totalPages){
        this.contentUpload(false, true);

      }
    },

    async contentUpload(event, skip_scroll_check=false){
      let scrolled = false;
      if(event){
        if (this.isMobileView) {
          scrolled = (event.target.scrollLeft*window.devicePixelRatio) >=
              ((event.target.scrollWidth - event.target.clientWidth));
        } else {
          scrolled = (event.target.scrollTop*window.devicePixelRatio) >=
              event.target.scrollHeight - event.target.clientHeight;
        }
      }

      if (
        (scrolled &&
        !this.pageControl.isLoading &&
        this.pageControl.currentPage < this.pageControl.totalPages
        ) || skip_scroll_check
      ) {
        const panel = this.panels.find((p) => p.id == this.selectedPanelId);
        if (!panel) return;

        this.pageControl.isLoading = true;
        const items = await this.getFiltersByParams(
          this.pageControl.currentPage + 1
        );

        await this.checkAfterLoad(items);
        this.pageControl.isLoading = false;
      }
    },

    updateFavorites() {
      this.favoritesList = this.$store.state.favorites.filters;

      this.favoritesList = this.favoritesList.map((fav) => {
        fav.favoritesIconIsChecked = true;
        if (fav.values) {
          fav.values = Object.entries(fav.values).map((res) => {
            return res && res.length
              ? { uniform: res[0], values: res[1] }
              : [{}];
          });
        }

        fav.effect = `effect-${Date.now()}`;

        return fav;
      });
    },
    cloneObjectAsVUE(object) {
      try {
        return JSON.parse(JSON.stringify(object));
      } catch (err) {
        console.error(err);
        return object;
      }
    },

    resetFilterParams(id) {
      const items = this.panelsCache.reduce(
        (arr, p) => [...arr, ...p.panelItems],
        []
      );
      const item = items.find((i) => i.id == id);

      if (item && item.params) {
        this.updateUniformsFilter(this.cloneObjectAsVUE(item.params));
      }
    },

    async changeEffectAlpha(alpha) {
      this.filterConfig.valueAlpha = alpha;
      await this.setupEffect();
    },

    async favoritesPropChange(itemId, panelId) {
      const { favoritesList, panels } = await this.$store.dispatch(
        "favoritesPropChange", {
          panelOpened: this.panelOpened,
          panels: this.panels,
          favoritesList: this.favoritesList,
          panelName: this.panelNameFavorite,
          itemId, panelId,
        }
      );

      this.favoritesList = favoritesList;
      this.panels = panels;
    },

    async discardChanges() {
      if (this.$store.state.premiumStatus) {
        this.$store.commit("changePremiumStatus", false);
      }

      this.$lib.watermark.remove(true);
      if (this.isLoadedPreview) {
        this.$lib.Drawing.initDraw(false);
        //await this.$lib._cache.renderSaveByTimestamp();
        this.$lib.filters.discardFilters();
        this.$store.dispatch("isUpdateCache");
        this.isLoadedPreview = false;
        this.$store.dispatch("fabricSlide", false);
        this.selectedParams = {};
      }
    },

    async applyFilter(params, slider=false) {
      let preset = this.getSelectedPreset();
      await this._preloader(true);

      if (this.isMobileView) {
        await this.delayFn(5000);
      }

      if (!preset) return;
      if (preset.isPremium) {
        const isHavePremium = await this.$store.dispatch(
          "isPremiumCurrentUser"
        );
        if (!isHavePremium) {
          // if (window.abVariant && window.abVariant == 2) {
                    this.$store.dispatch("popupsToggle", {
                        property: "stripeCheckoutPopup",
                        value: true,
                    });
                // }
                // else{
                //     this.$store.dispatch("popupsToggle", {
                //         property: "upgradePopup",
                //         value: true,
                //     });
                // }
          await this._preloader(false);
          return;
        }
      }

      this.appliedPresetId = false;
      this.lockPanelWatch = true;
      // await this.setupEffect({ isApply: true });
      await this.$lib.filters.filterUtil.addToCache();

      this.resetDrawing();

      this.discardChanges();
      this.$lib.Drawing.initDraw(false);
      this.$lib.watermark.remove();

      if(slider) {
        let height = this.$lib.app.fabricCanvas.height;
        let nowHeight, zoom;

        nowHeight = height - 31;
        zoom = nowHeight/this.$lib.app.stage.height;
        $(".centered-content").css({'transform': `scale(${zoom})`});
        $(".drag-wrapper").css({'height': 'calc(100vh - 280px)'})
        this.$lib.app.setFabricZoom(zoom);
      }
      this.$store.dispatch("changesApplied");
      this.$store.dispatch("isUpdateCache");
      this.$store.dispatch("fabricSlide", false);

      this.panelOpened = "";
      this.panelClass = "";
      this.panelLoading = true;
      this.popUpVisible = false;
      this.showPanel = true;

      this.selectPointerEvents(true);

      if (this.isMobileView) {
        setTimeout(() => {
          this._preloader(false);
          }, 1000);
      } else {
        await this._preloader(false);
      }
    },

    panelView(val) {
      this.showPanel = val;
    },

    async updateUniformsFilter(uniforms) {
      this.selectedParams = {
        ...(this.selectedParams ? this.selectedParams : {}),
        ...(uniforms || {}),
      };

      await this.setupEffect();
    },

    getSelectedPreset() {
      let preset = [
        ...this.panels.reduce((arr, panel) => {
          if (panel.panelItems) {
            arr = [...arr, ...panel.panelItems];
          }

          return arr;
        }, []),
      ].find((item) => item.id == this.currentFilter);

      if (!preset) {
        preset = this.favoritesList.find(
          (itemFav) => itemFav.id == this.currentFilter
        );
      }

      return preset;
    },

    async filterPreview(data) {
      if (this.appliedPresetId == data.id) {
        this.appliedPresetId = null;
        this.discardChanges();
        this.popUpVisible = false;

        return;
      }

      this.resetDrawing();
      this.selectedParams = {};
      this.$store.dispatch("fabricSlide", true);
      //this.$store.dispatch("previewComponents", this.$store.state.prewCompCodes.filter);

      this.$store.dispatch("checkPremiumStatus", {
        panels: this.panels,
        favorites: this.favoritesList,
        presetId: data.id,
      });

      this.currentFilter = data.id;
      this.selectedParams = JSON.parse(JSON.stringify(data.params));
      this.freezeSelectedParams = JSON.parse(JSON.stringify(data.params));
      this.selectedEffect = data.effect;
      this.selectedValues = data.values;

      this.filterConfig.valueAlpha = this.filterConfig.defaultValueAlpha;
      await this.setupEffect(undefined, true);

      this.appliedPresetId = data.id;
    },

    async setupEffect(params = {}, isTick = false) {
      if (this.isMobileView) {
        await this.delayFn(2000);
      }
      const ticker = () => {
          const sprite = this.exportDraw();
          this.setMaskTexture(sprite, true, true);
      };

      const preset = this.getSelectedPreset();
      if (!this.currentFilter && this.currentFilter !== 0 || !preset) {
        return;
      }

      if(params.isApply) {
        await this.applyMixin();
      }

      this.$lib.watermark.renderPremium(preset.isPremium && !this.$store.state.isPremiumAccount, undefined, [false, true]);

      if(this.beforeDestroyVar) return;
      await this.$lib.filters.filterUtil.setupFilters(this.selectedParams, {
        alpha: this.filterConfig.valueAlpha,
        ...params, maskTexture: this.maskTexture, id: 1,
      }, this.eraseData, ticker);

      this.isLoadedPreview = true;

      if(!this.isMobileView && isTick) { ticker(); }
    },

    delayFn(delayInms) {
      return new Promise(resolve => setTimeout(resolve, delayInms));
    },

    popUpPropChange(label) {
      if (
        this.popUpVisible !== false &&
        this.popUpLabel !== label &&
        label !== ""
      ) {
        return (this.popUpLabel = label);
      } else {
        return (
          (this.popUpVisible = !this.popUpVisible), (this.popUpLabel = label)
        );
      }
    },

    async getFiltersByParams(page = 1) {
      this.pageControl.currentPage = page;
      const filters =
        (await fetch(
          `${this.$store.state.prefixURL}/api/filters/${this.selectedPanelId}?page=${page}`
        ).then((res) => res.json())) || [];
      this.pageControl.totalPages = filters.total_pages;

      // filters.data = filters.data.map(d => {
      //   const curvesUpdate = this.curvesUpdate.find(cud => cud.id == d.id);
      //   if(curvesUpdate && d.params) {
      //     d.params.curvesFilter_p = curvesUpdate.data;
      //   }

      //   return d;
      // });
      return filters.data
        .map((item) => (item.params ? item : null))
        .filter((item) => item);
    },

    async toolLinkClick(title, panelId) {
      this.updateFavorites();
      //открывает список фильтров в категории.
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      if (this.panelOpened === title) {
        this.panelOpened = "";
        this.panelClass = "";
        this.isLoading = true;
      } else {
        analitics.sendEvent(title, analitics.categ.filter);

        this.panelLoading = true;

        this.panelOpened = title;
        this.panelClass = "active";
        const panelIndex = this.panels.findIndex(
          (panel) => panel.title == title
        );

        this.selectedPanelId = this.panels[panelIndex].id;
        this.panels[panelIndex].panelItems = [];
        const items = await this.getFiltersByParams();
        await this.checkAfterLoad(items);
      }

      this.showPanel = false;
    },

    async checkAfterLoad(items = []) {
      const panelIndex = this.panels.findIndex(
        (panel) => panel.id === this.selectedPanelId
      );
      if (panelIndex == -1) return;

      items = items.map((item) => {
        if (this.favoritesList.find((fi) => fi.id == item.id)) {
          item.favoritesIconIsChecked = true;
        }

        if (item.values) {
          item.values = Object.entries(item.values).map((res) => {
            return res && res.length
              ? { uniform: res[0], values: res[1] }
              : [{}];
          });
        }

        item.effect = `effect-${this.panels[panelIndex].id}`;
        return item;
      });

      this.panels[panelIndex].panelItems = [
        ...(this.panels[panelIndex].panelItems || []),
        ...items,
      ];

      if (!this.panelsCache.find((p) => p.id == this.panels[panelIndex].id)) {
        this.panelsCache = this.cloneObjectAsVUE([
          ...this.panelsCache, this.panels[panelIndex],
        ]);
      }

      if (panelIndex >= 0) {
        const stageBase64 = await this.$lib._cache.getLastThumbBase64();
        const cachedResource = await this.$lib.app.addToLoader(
          `${Date.now()}-thumb`,
          stageBase64
        );

        const st = Date.now();
        for (let i = 0; i < this.panels[panelIndex].panelItems.length; i++) {
          if (
            this.panels[panelIndex].panelItems[i] &&
            !this.panels[panelIndex].panelItems[i].genImage
          ) {
            const prevImage = await this.$lib.filters.filterUtil.previewCompareFiltersBase64(
              cachedResource.texture.clone(),
              JSON.parse(
                JSON.stringify(this.panels[panelIndex].panelItems[i].params)
              )

              // Костыль
            );

            if (prevImage) {
              this.panels[panelIndex].panelItems[i].imgPath = prevImage;
              this.panels[panelIndex].panelItems[i].genImage = true;
              this.panels = [...this.panels];
            }
          }
        }

        console.log(`${Date.now() - st} ms.`);
      }

      this.panelLoading = false;
    },

    panelPropChange() {
      this.appliedPresetId = false;
      //TODO закрываем панель
      this.discardChanges();
      this.panelOpened = "";
      this.panelClass = "";
      this.panelLoading = true;
      this.popUpVisible = false;
      this.popUpVisible = false;

      this.resetDrawing();
    },

    async favoritesOpen() {
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      this.panelOpened = "Favorites";
      this.panelClass = "active";
      this.panelLoading = true;

      const stageBase64 = await this.$lib._cache.getLastThumbBase64(); //await this.$lib.app.extractStage();
      const cachedResource = await this.$lib.app.addToLoader(
        `${Date.now()}-thumb`, stageBase64
      );

      for (let i = 0; i < this.favoritesList.length; i++) {
        const prevImage = await this.$lib.filters.filterUtil.previewCompareFiltersBase64(
          cachedResource.texture.clone(),
          JSON.parse(JSON.stringify(this.favoritesList[i].params))
        );

        if (prevImage) {
          this.favoritesList[i].imgPath = prevImage;
        }
      }
      this.panelLoading = false;
    },
  },

  beforeDestroy() {
    this.beforeDestroyVar = true;
    this.resetDrawing(false, true);

    this.discardChanges();
    this.$lib.Drawing.initDraw(false);
    this.$lib.watermark.remove();
    this.selectPointerEvents(false);
  },
};
</script>
<style scoped>
  .hidden {
    display: none;
  }
</style>
