import DropdownSelectCrop from "@/components/DropdownSelectCrop.vue";
import InputWidthHeight from "@/components/InputWidthHeight.vue";
import ToggleCheck from "@/components/ToggleCheck.vue";
import Range from "@/components/Range";
import ColorInput from "@/components/ColorInput";
import DropdownSelect from "@/components/DropdownSelect";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";
import PanelAccordionAi from "@/components/PanelAccordionAi.vue";
import * as faceapi from 'face-api.js';
import VideoPreviewTooltip from '../../VideoPreviewTooltip.vue';
import TooltipAI from './TooltipAI.vue';
import { trackMxEvent } from '@/libs/mixpanel.js';

export default {
    components: {
        DropdownSelectCrop,
        InputWidthHeight,
        ToggleCheck,
        Tips, Range,
        AdLeftPanel,
        ColorInput,
        DropdownSelect,
        PanelAccordionAi,
        VideoPreviewTooltip,
        TooltipAI
    },
    props: {
        editPanelClass: String,
        editPanelPropChange: Function,
        closeCurrentEditor: Function,
        applyChanges: Function,
    },

    data: function () {
        return {
            tipsText:
                "The Crop tool allows you to trim away unnecessary parts of your image.",

            helpIcon: require("@/assets/img/help-icon.svg"),
            payload: {},
            dropdowns: {}
        };
    },

    async mounted() {
        window.dd = this;
        this.$store.state.isArtificialIntelligencePlan = true;
    },

    beforeDestroy() {
        this.$store.state.isArtificialIntelligencePlan = false;
    },

    methods: {
        changeDropdown(key, isOpen = true) {
            this.dropdowns[key] = !!isOpen ? 'active' : '';
        },

        changeRange(key) {
            return (value) => {
                this.payload[key] = value;

                this.dropdowns[key] = '';
            }
        },

        checkToggle(key, e) {
            this.payload[key] = e.target.checked;
        },

        async generatePayload(image) {
            return { ...this.payload, image };
        },

        showErrorMessage(message = "Something went wrong. Try again later.", err = null) {
            if (err) console.error(err);
            alert(message);
        },

        async blobToB64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(blob);
            });
        },

        async validateFaces() {
            console.log("face validation not required");
            return { success: true };
        },

        async applyIt() {
            if(!this.allowAiTools){
                if (!this.isLoggedIn && this.allowSocialLogin) {
                    return this.changePopup("socialLoginPopup", true);
                }
                return this.changePopup("stripeCheckoutPopup", true);
            }

            this.$store.dispatch("preloader", { message: "Applying the effect...", isShow: true });
            let validation_result = await this.validateFaces();

            if (!validation_result.success) {
                console.log("validation_result", validation_result);
                this.$store.dispatch("preloader", { isShow: false });

                this.$store.dispatch("popupsToggle", {
                    property: "facesPopup",
                    value: validation_result.error,
                });
                return false;
            }

            //            if(true){ //this.faces_max){
            //		        let blob = await this.$aitool.getCurrentBlobImage();
            //		        let b64 = await this.blobToB64(blob);
            //		        let faces = await this.detectFaces(b64);
            //		        if(true){ //faces>this.faces_max){
            //		        	this.$store.dispatch("preloader", { isShow: false });
            //					this.$store.dispatch("popupsToggle", {
            //						property: "facesPopup",
            //						value: this.faces_error,
            //					});
            //		        	return false;
            //		        }
            //            }


            try {
                const isBgRemover = this.toolApi == this.$aitool.tools.BackgroundRemovalAPI
                const image = await this.$aitool.getCurrentStateImage(false, isBgRemover);
                if (image.message) {
                    this.showErrorMessage(image.message, {});
                    this.changePopup("stripeCheckoutPopup", true);
                }
                else{
                    await this.$aitool.applyEffect(this.toolApi, await this.generatePayload(image.url), this.isFreeUser);this.$store.dispatch('changesApplied');
                    if (!isBgRemover || (isBgRemover && !this.isPremium)) {
                        await this.$aitool.aiCreditUsage();
                        this.$store.dispatch('refreshUser');
                    }
                }
                
            } catch (err) {
                try {
                    trackMxEvent(`AI failed - ${this.aiToolName(this.toolApi)}`)
                } catch (error) {
                    // 
                }
                this.showErrorMessage(undefined, err);
                
            }
            this.$store.dispatch("preloader", { isShow: false });
        },

        openDropdown() {
            this.selectDropdown = "active";
        },

        popUpPropChange(property, value) {
            this.$store.dispatch("popupsToggleStatus", { property, value });
        },

        changePopup(property, value) {
            this.$store.dispatch("popupsToggle", { property, value });
        },


		async detectFaces(b64){
			console.time("face-recognition")
			await faceapi.nets.ssdMobilenetv1.loadFromUri('/face-models');
			const img = new Image();
			img.src = b64; //`data:image/jpeg;base64,${base64String}`;
			await new Promise((resolve) => (img.onload = resolve));
			const detections = await faceapi.detectAllFaces(img);	
//			alert(`Faces detected: ${detections.length}`);
			console.timeEnd("face-recognition");
			return detections.length;
		},
        aiToolName(tool){
            const AiTools = this.$aitool.tools
            return Object.keys(AiTools).find(key => AiTools[key] === tool) || null;
        },
        
    },

    computed: {
        isLeftAd() {
            return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
        },
        toolApi() {
            return this.$aitool.tools.BackgroundRemovalAPI;
        },

        getPopupStatus() {
            return this.$store.state.global.popupStatus.value;
        },

        allowAiTools() {
            return this.$store.state.allowAiTools;
        },

        isPremium() {
            return this.$store.state.isPremiumAccount;
        },
        isFreeUser() {
            return this.$store.state.USER.status === 'free';
        },
        isLoggedIn() {
            return this.$store.state.USER;
        },
        allowSocialLogin() {
            return this.$store.state.INFO?.allow_social_login;
        },
    }
};
