module.exports = class {
    constructor(app) {
        this.app = app;
        window.aitool = this;
        // _LIB.CONST.MAP_CHANGES.DRAW
        this.authorization = 'LIMBYKR4ECPI7UZ61H9RZQIC5W0D0PKSZDHQE5J7';
        this.tools = {
            BackgroundRemovalAPI: `https://api.runpod.ai/v2/6tvjeen0yxy58k`,
            FaceRestorationAPI: `https://api.runpod.ai/v2/ao7zxxsc2xpb0l`,
            ImageDeoldifyAPI: `https://api.runpod.ai/v2/4pjqzrpnp8eg9s`,
            ImageToPromptAPI: `https://api.runpod.ai/v2/rymf5f9uc14ykt`,
            ImageUpscalerAPI: `https://api.runpod.ai/v2/bixj497i8glvnj`,
            ObjectRemovalAPI: `https://api.runpod.ai/v2/lma71mikdsz49x`,
            PhotoToAnimeAPI: `https://api.runpod.ai/v2/ax79jk2eac00h2`,
            FaceToStickerAPI: `https://api.runpod.ai/v2/0mca4xge5zoy5w`
        };
    }

    get $lib() {
        return this.app.$lib;
    }

//    getCurrentBlobImage() {
//        /// const s = await _LIB.fabric.getLastBase64Pixi(_LIB._cache.selectedChangeTimestamp || Date.now());
//        return this.$lib.app.getExportImage('jpeg', 90);
//    }

	async getCurrentBlobImage() {
		/// const s = await _LIB.fabric.getLastBase64Pixi(_LIB._cache.selectedChangeTimestamp || Date.now());
		const blob = await this.$lib.app.getExportImage('jpeg', 90);
		return this.resizeImage(URL.createObjectURL(blob), 1200, 1200);
	}


	async resizeImage(blobUrl, width, height) {
		return new Promise((resolve, reject) => {
		    const img = new Image();
		    img.onload = () => {
		        let targetWidth = img.width, targetHeight = img.height;
		        if(img.width > width || img.height > height) {
		            const aspectRatio = img.width / img.height;
		
		            if (width / height > aspectRatio) {
		                targetHeight = height;
		                targetWidth = height * aspectRatio;
		            } else {
		                targetWidth = width;
		                targetHeight = width / aspectRatio;
		            }
		        }
		        const canvas = document.createElement('canvas');
		        canvas.width = targetWidth;
		        canvas.height = targetHeight;

		        const ctx = canvas.getContext('2d');
		        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
		        canvas.toBlob(resolve, 'image/jpeg', 0.9);
		    };

		    img.onerror = (err) => reject(err);
		    img.src = blobUrl;
		});
	}



    async getDrawingBlobMask(isFull = false) {
        if (isFull) {
            this.$lib.Drawing.canvas.backgroundColor = '#000000';
            this.$lib.Drawing.canvas.renderAll();
        }

        const blob = await new Promise(resolve => this.$lib.Drawing.canvas.lowerCanvasEl.toBlob(resolve));
        if (isFull) {
            this.$lib.Drawing.canvas.backgroundColor = '';
            this.$lib.Drawing.canvas.renderAll();
        }

        return blob;
    }

    extractOptimizedThumb(w, h) {
        return this.$lib.app.extractOptimizedThumb(w, h);
    }

    async _loadFromCache() {
        const base64 = localStorage.getItem('base');

        const sprite = new PIXI.Sprite((await this.$lib.app.addToLoader(`${Date.now()}-ai-image`, base64)).texture);
        
        await this.$lib.app.replaceBackgroundStage(sprite);
        await this.$lib.app.reRenderCanvas();

        await this.$lib._cache.addToBgCacheUpdating(73442, {}, true, { isThumb: true, base64 });
        this.$lib._cache.selectedChangeTimestamp = this.$lib._cache.getHistoryChanges().at(-1)?.timestamp;

        this.app.$store.state.fitToScreenComp += 1;
    }

    async applyEffect(toolURL, options = {}, applyWaterMark = false) {
        const base64 = await this.processingEffect(toolURL, options);
        const sprite = new PIXI.Sprite((await this.$lib.app.addToLoader(`${Date.now()}-ai-image`, base64)).texture);
        
        await this.$lib.app.replaceBackgroundStage(sprite);
        if (applyWaterMark) {
            this.$lib.watermark.render(this.$lib.watermark.isEqualRatio(sprite), false, true);
        }
        await this.$lib.app.reRenderCanvas();

        await this.$lib._cache.addToBgCacheUpdating(73442, {}, true, { isThumb: true, base64 });
        this.$lib._cache.selectedChangeTimestamp = this.$lib._cache.getHistoryChanges().at(-1)?.timestamp;

        this.app.$store.state.fitToScreenComp += 1;
    }

    async getCurrentStateImage(blob = false, isBgRemover = false) {
        if (!blob) blob = await this.getCurrentBlobImage();
        if(!window._cacheAIProxy) window._cacheAIProxy = [];
        window._cacheAIProxy.push(blob);

        const formData = new FormData();
        formData.append('image', blob);
        formData.append('isBgRemover', isBgRemover);

        return fetch('/ai-proxy', {
            method: 'POST', body: formData
        }).then(res => res.json()).then(res => res);
    }

    async aiCreditUsage() {
        return fetch('/ai-generated', {
            method: 'GET'
        }).then(res => res.json()).then(res => res);
    }

    get headers() {
        return {
            authorization: this.authorization,
            'content-type': 'application/json',
            accept: 'application/json'
        };
    }

    async awaitingStatusCompleted(url, id, startTime = Date.now()) {
        const isLongTimeout = (Date.now() - startTime) > (10 * 60 * 1000);
        if (isLongTimeout) throw new Error('Timeout exceeded. Please try again.');

        let response = await fetch(`${url}/status/${id}`, {
            method: 'GET', headers: this.headers
        }).then(res => res.json());

        if (response.status === 'COMPLETED') return response;
        if (response.status === 'FAILED') throw new Error(response.error || 'Error while fetching');
        return new Promise(resolve => setTimeout(() => { resolve(this.awaitingStatusCompleted(url, id, startTime)) }, 2000));
    }



    async processingEffect(toolURL, options = {}) { // runsync
        let response = await fetch(`${toolURL}/run`, {
            method: 'POST', headers: this.headers,
            body: JSON.stringify({ input: options })
        }).then(res => res.json());

        if (['IN_QUEUE', 'IN_PROGRESS'].includes(response.status) && response.id) {
            response = await this.awaitingStatusCompleted(toolURL, response.id, Date.now());
        }

        if (response.status === 'COMPLETED') {
            if (!response.output) throw new Error('Image output is empty. Something went wrong.');
            if (Array.isArray(response.output)) return response.output[0];

            return response.output;
        } else {
            throw new Error(`Failed to apply effect: ${response.error}`);
        }
    }
}
