<template>
  <div :class="[{ upgrade_mobile: isMobileView }]">
    <div class="form_loading" v-if="isLoading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <!-- Register variant -->
    <div v-if="!isLoggedIn || isSubmitting">
      <div class="form_loading" v-if="isSubmitting"></div>
      <div
        class="popup save_popup upgrade-popup upgrade-popup--small subs_popup reg-var"
        style="height: 670px"
        v-if="confirmSubscriptionPopup && isOnline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="30"
          viewBox="0 0 36 30"
        >
          <g id="emblem" transform="translate(-586 -239)">
            <path
              id="bg"
              d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
              transform="translate(586 239)"
              fill="#ff9360"
            />
            <path
              id="plus"
              d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
              transform="translate(552.992 245)"
              fill="#fff"
            />
          </g>
        </svg>

        <div :class="{ is_pwa_popup: is_pwa }">
          <div
            class="content content--small"
            style="margin-top: 10px; width: 348px"
          >
            <div class="">
              <div class="">
                <h2
                  class="title old_update_popup_1"
                  style="
                    text-align: unset;
                    font-size: 26px;
                    text-align: left;
                    margin-bottom: 35px;
                    letter-spacing: -1.04px;
                    max-width: 340px;
                    font-weight: 600;
                  "
                >
                Upgrade to unlock all effects & premium AI features.
                </h2>

                <TogglePlanPeriod
                  @change="changePeriod"
                  :period="period"
                  :isRegistrationMode="true"
                />

                <div class="radio-group">
                  <input
                    type="radio"
                    v-if="_defSelectedPlan"
                    id="plus-r"
                    name="upgrade-plan-switch"
                    class="radio-input"
                    v-model="mode"
                    value="premium"
                  />
                  <label
                    for="plus-r"
                    class="radio-label"
                    v-if="_defSelectedPlan"
                  >
                    <span class="radio-circle"></span>
                    <span class="radio-text">
                      <span style="color: #fa6400">PRO</span>
                      <strong class="price-text">
                        <span v-if="this.period === 'yearly'" style="font-size: 14px; color: #1d1d1d; margin-right: 0.5em;">
                          <strong class="text-strike">
                            ${{ getMonthlyPrice() }}/mo
                          </strong>
                        </span>
                        ${{ _defSelectedPlan.billed_mo }}/mo
                        </strong>
                    </span>
                  </label>

                  <input
                    type="radio"
                    v-if="_aiSelectedPlan"
                    id="plus-r-ai"
                    name="upgrade-plan-switch"
                    class="radio-input"
                    v-model="mode"
                    value="premium-ai"
                  />
                  <label
                    for="plus-r-ai"
                    class="radio-label"
                    v-if="_aiSelectedPlan"
                  >
                    <span class="radio-circle"></span>
                    <span class="radio-text">
                      <span style="color: #1c7ea8">Total AI</span>
                      <strong class="price-text" >
                        <span v-if="this.period === 'yearly'" style="font-size: 14px; color: #1d1d1d; margin-right: 0.5em">
                          <strong class="text-strike">
                            ${{ getMonthlyPrice(true) }}/mo
                          </strong>
                        </span>
                        ${{ _aiSelectedPlan.billed_mo }}/mo</strong
                      >
                    </span>
                  </label>
                </div>

                <p
                 class="billing_term" >
                    <strong v-if="this.period === 'yearly'"
                    >Billed annually at ${{ mode === 'premium-ai' ? _aiSelectedPlan.price : _defSelectedPlan.price }}</strong>
                    <strong v-else
                    >Billed monthly</strong>
                  </p>
                <p
                  class="all-plus-future"
                  style="margin-left: 0px"
                >
                <span v-if="this.period === 'yearly'" >
                  Get access to all {{ mode === 'premium-ai' ? 'Total AI' : 'Pro' }} features for one low price. Save big with a yearly plan. Cancel anytime!
                </span>
                <span v-else >
                  {{ mode === 'premium-ai' ? 'Total AI' : 'Pro' }} access made easy – pay monthly and enjoy flexibility. Cancel anytime!
                </span>
                  
                </p>
              </div>
            </div>

            <div
              v-if="!is_pwa"
              :style="{ 'text-align': 'center', 'margin-top': '30px' }"
            >
              <div class="">
                <form v-on:submit.prevent="onSubmit" class="reg-form">
                  <div>
                    <input
                      type="email"
                      ref="xemail"
                      :v-model="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Email address"
                      required
                    />
                  </div>
                  <div style="margin-top: 16px">
                    <input
                      type="password"
                      ref="xpassword"
                      :v-model="password"
                      class="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      required
                      minlength="5"
                    />
                  </div>
                  <div class="form_errors" v-if="formErrors">
                    <ul id="example-1">
                      <li v-for="(item, index) in formErrors" :key="index">
                        {{ item[0] }}
                      </li>
                    </ul>
                  </div>

                  <div style="margin-top: 24px">
                    <button
                      type="submit"
                      class="btn-orange"
                      style="
                        color: #fff;
                        margin: 0;
                        max-width: 100%;
                        height: 48px;
                        font-size: 12px;
                        box-shadow: 0px 2px 24px #fa640066;
                      "
                    >
                      SIGN UP
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div style="text-align: center; margin-top: 14px">
              <span class="already-member">
                Already a member?
                <a
                  href="#"
                  @click.prevent="showLoginPopUp"
                  style="
                    text-align: left;
                    text-decoration: underline;
                    font: normal normal 300 14px/20px Soleil;
                    letter-spacing: -0.28px;
                    color: #fa6400;
                  "
                >
                  Sign in
                </a>
              </span>
            </div>

            <div class="agree-links">
              <span
                >By signing up, you agree to the Colorcinch
                <a
                  href="https://www.cartoonize.net/privacy-policy/"
                  target="_blank"
                  >Privacy Policy</a
                >
                and
                <a
                  href="https://www.cartoonize.net/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Terms of Service</a
                >.</span
              >
            </div>
          </div>
          <div class="teaser-modal__image"></div>
          <div class="plus-benefits fix_ai_top_list">
            <p
              style="
                font-size: 16px;
                color: #1d1d1d;
                letter-spacing: -0.64px;
                text-align: left;
                font-weight: normal;
                font-family: Soleil;
                margin-bottom: 12px;
              "
            >
            <strong>{{ mode === 'premium' ? 'Pro users also get:': 'Everything in Pro and:' }}</strong>
            </p>
            <ul class="list-plus" v-if="mode === 'premium'">
              <li>Unlimited AI Background Remover</li>
              <li>Other AI features: 25 images/month</li>
              <li>Artistic filters, overlays & masks</li>
              <li>Smart editing tools & more</li>
            </ul>

            <div class="double--column" v-else>
              <ul class="list-plus list-plus--ai">
                <li v-for="(lai, i) of listAiItems" :key="i">{{ lai }}</li>
                <li class="f-width">Unlimited Images for AI Processing</li>
                <li class="f-width">Bulk Processing (Coming Soon)</li>
                <!-- <li>Face Restoration</li>
                    <li>Inpainting & Removal</li>
                    <li>Background Removal</li> -->
              </ul>
            </div>
          </div>

          <div>
            <a
              class="close_popup_btn"
              v-on:click="changePopup('stripeCheckoutPopup', false)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <g id="x" transform="translate(-198 -160)">
                  <circle
                    id="Ellipse_106"
                    data-name="Ellipse 106"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(198 160)"
                    fill="#000"
                    opacity="0.5"
                  />
                  <g
                    id="x_icon"
                    data-name="x icon"
                    transform="translate(203 165)"
                  >
                    <rect
                      id="Rectangle_435"
                      data-name="Rectangle 435"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(7.308 8) rotate(-135)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_436"
                      data-name="Rectangle 436"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(0 7.308) rotate(-45)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </a>

            <div v-if="!isMobileView" id="img-area-register">
              <div id="img-area-register2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Normal Variant -->
    <div v-else style="overflow: hidden">
      <div class="form_loading" v-if="isRedirecting"></div>
      <div
        class="popup save_popup upgrade-popup subs_popup"
        v-if="confirmSubscriptionPopup && isOnline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="30"
          viewBox="0 0 36 30"
        >
          <g id="emblem" transform="translate(-586 -239)">
            <path
              id="bg"
              d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
              transform="translate(586 239)"
              fill="#ff9360"
            />
            <path
              id="plus"
              d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
              transform="translate(552.992 245)"
              fill="#fff"
            />
          </g>
        </svg>

        <div :class="{ is_pwa_popup: is_pwa }">
          <TogglePlanPeriod @change="changePeriod" :period="period" />

          <div
            class="content"
            style="
              width: 552px;
              display: flex;
              justify-content: space-between;
              gap: 32px;
            "
          >
            <div class="content-section" v-if="_defSelectedPlan">
              <div class="">
                <div class="">
                  <h2
                    class="title old_update_popup_1"
                    style="
                      text-align: unset;
                      font-size: 26px;
                      text-align: left;
                      margin-bottom: 10px;
                    "
                  >
                    <span style="color: #fa6400">PRO</span>
                  </h2>

                  <p v-if="this.period === 'yearly'" style="font-size: 14px; color: #1d1d1d; margin-bottom: 8px">
                    <strong class="text-strike">
                      ${{ getMonthlyPrice() }}/mo
                    </strong>
                  </p>
                  <p
                    style="font-size: 26px; color: #1d1d1d; margin-bottom: 15px"
                  >
                    <strong>${{ _defSelectedPlan.billed_mo }}/mo</strong>
                  </p>

                  <p class="billing_term" >
                    <strong v-if="this.period === 'yearly'"
                    >Billed annually at ${{ _defSelectedPlan.price }}</strong>
                    <strong v-else
                    >Billed monthly</strong>
                  </p>

                  <p
                    class="all-plus-future"
                    style="margin-bottom: 15px; margin-left: 0px"
                  >
                  <span v-if="this.period === 'yearly'" >
                    Get access to all Pro features for one low price. Save big with a yearly plan. Cancel anytime!
                  </span>
                  <span v-else >
                    Pro features access made easy – pay monthly and enjoy flexibility. Cancel anytime!
                  </span>
                  </p>
                  <div v-if="!isMobileView">
                    <p
                      style="
                        font-size: 16px;
                        color: #1d1d1d;
                        letter-spacing: -0.64px;
                        text-align: left;
                        font-weight: normal;
                        font-family: Soleil;
                        margin-bottom: 12px;
                      "
                    >
                      <strong>Pro users also get:</strong>
                    </p>
                    <ul class="list-plus">
                      <li>Unlimited AI Background Remover</li>
                      <li>Other AI features: 25 images/month</li>
                      <li>Artistic filters, overlays & masks</li>
                      <li>Smart editing tools</li>
                      <li>Extended graphics library</li>
                      <li>Fullscreen mode</li>
                    </ul>
                  </div>
                  
                </div>
              </div>

              <div
                v-if="!is_pwa"
                :style="{ 'text-align': 'center', 'margin-top': '15px' }"
              >
                <a
                v-if="!isPremium"
                  :class="{ disabled: isPremium }"
                  href="javascript:void(0)"
                  @click="redirectToUpgrade(false)"
                  class="btn-orange"
                  style="color: #fff; margin: 0; max-width: 100%; height: 48px"
                  >{{ upgradeText }}</a
                >
              </div>
            </div>

            <div class="content-section" v-if="_aiSelectedPlan">
              <div class="plus-ai-features">
                <div class="">
                  <h2
                    class="title old_update_popup_1"
                    style="
                      text-align: unset;
                      font-size: 26px;
                      text-align: left;
                      margin-bottom: 10px;
                    "
                  >
                    <span style="color: #1c7ea8">Total AI</span>
                  </h2>

                  <p v-if="this.period === 'yearly'" style="font-size: 14px; color: #1d1d1d; margin-bottom: 8px">
                    <strong class="text-strike">
                      ${{ getMonthlyPrice(true) }}/mo
                    </strong>
                  </p>
                  <p
                    style="font-size: 26px; color: #1d1d1d; margin-bottom: 15px"
                  >
                    <strong>${{ _aiSelectedPlan.billed_mo }}/mo</strong>
                  </p>
                  <p class="billing_term" >
                    <strong v-if="this.period === 'yearly'"
                    >Billed annually at ${{ _aiSelectedPlan.price }}</strong>
                    <strong v-else
                    >Billed monthly</strong>
                  </p>

                  <p
                    class="all-plus-future"
                    style="margin-bottom: 15px; margin-left: 0px"
                  >
                  <span v-if="this.period === 'yearly'" >
                    Get access to all Total AI features for one low price. Save big with a yearly plan. Cancel anytime!
                  </span>
                  <span v-else >
                    Total AI access made easy – pay monthly and enjoy flexibility. Cancel anytime!
                  </span>
                  </p>

                  <div v-if="!isMobileView">
                    <p
                      style="
                        font-size: 16px;
                        color: #1d1d1d;
                        letter-spacing: -0.64px;
                        text-align: left;
                        font-weight: normal;
                        font-family: Soleil;
                        margin-bottom: 12px;
                      "
                    >
                      <strong>Everything in Pro and:</strong>
                    </p>
                    <ul class="list-plus list-plus--ai">
                      <li>Unlimited Images for AI Processing</li>
                      <li v-for="(lai, i) of listAiItems" :key="i">{{ lai }}</li>
                      <li>Bulk Processing (Coming Soon)</li>
                    </ul>
                  </div>
                  
                </div>
              </div>

              <div
                v-if="!is_pwa"
                :style="{ 'text-align': 'center', 'margin-top': '15px' }"
              >
                <a
                  href="javascript:void(0)"
                  @click="redirectToUpgrade(true)"
                  class="btn-orange"
                  style="
                    color: #fff;
                    margin: 0;
                    max-width: 100%;
                    height: 48px;
                    background-color: #1c7ea8;
                  "
                  >Upgrade Now</a
                >
              </div>
            </div>

            <div style="position: absolute; bottom: 0; left: 0; width: 260px;" class="form_errors" v-if="formErrors">
              {{ formErrors }}
            </div>

          </div>

          <div>
            <a
              class="close_popup_btn"
              v-on:click="changePopup('stripeCheckoutPopup', false)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <g id="x" transform="translate(-198 -160)">
                  <circle
                    id="Ellipse_106"
                    data-name="Ellipse 106"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(198 160)"
                    fill="#000"
                    opacity="0.5"
                  />
                  <g
                    id="x_icon"
                    data-name="x icon"
                    transform="translate(203 165)"
                  >
                    <rect
                      id="Rectangle_435"
                      data-name="Rectangle 435"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(7.308 8) rotate(-135)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_436"
                      data-name="Rectangle 436"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(0 7.308) rotate(-45)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <div v-if="!isMobileView" id="img-area">
              <div id="img-area2" style="opacity: 0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Acc from '@/libs/acc.js';
const analitics = require('@/libs/analytics');
import TogglePlanPeriod from '../TogglePlanPeriod.vue';

export default {
  name: 'StripeCheckoutPopupNew',
  components: { TogglePlanPeriod },
  props: {
    yesUpgrade: Function,
  },

  destroyed() {
    clearInterval(this.myInterval);
  },
  created() {
    this.myInterval = window.setInterval(function () {
      const img2 = document.querySelector('#img-area2');
      if (img2) {
        if (getComputedStyle(img2).opacity == '1') {
          img2.style.opacity = '0';
        } else {
          img2.style.opacity = '1';
        }
      }

      const imgRegister2 = document.querySelector('#img-area-register2');
      if (imgRegister2) {
        if (getComputedStyle(imgRegister2).opacity == '1') {
          imgRegister2.style.opacity = '0';
        } else {
          imgRegister2.style.opacity = '1';
        }
      }
    }, 2000);
  },

  data: function () {
    return {
      formErrors: null,
      acc: new Acc(),
      isSubmitting: false,
      isRedirecting: false,
      email: '',
      password: '',
      isMobileView: false,
      myInterval: null,
      is_pwa: false,
      confirmSubscriptionPopup: true,
      isLoading: false,
      mode: 'premium-ai',
      period: 'yearly',
    };
  },

  mounted() {
    
    this.isMobileView = this.$store.state.isMobileView;
    // if (this.currentPlanTerm) {
    //   this.period = this.currentPlanTerm + 'ly'
    // }
    if (this.$store.state.isArtificialIntelligencePlan)
      this.mode = 'premium-ai';
  },

  methods: {
    async upgradePlan(planId) {
			
			if(this.is_twa || window.debug_twa){
				// AI not implemented on google Android yet
			}else{
				if(planId == null){
					return false;
				}
				const result = await this.acc.changePlan(planId);
				if (result.success) {
          window.location.replace(
            window.location.origin + `/pay/success`
          );
				}
        else{
          this.formErrors = "Something went wrong. Please update your payment info and try again.";
          return false;
        }
			}
		},
    async redirectToUpgrade(isAiPremium = false) {
      if (isAiPremium && this.isPremium) {
        this.isRedirecting = true;
        this.upgradePlan(this._aiSelectedPlan.id)
        return
      }
      window.sendinblue.track('checkout', { checkoutType: 'direct' });
      this.isRedirecting = true;

      const planId = isAiPremium
        ? this._aiSelectedPlan.id
        : this._defSelectedPlan.id;
      window.location.replace(
        window.location.origin + `/upgrade-now?plan=${planId}`
      );
    },

    async onSubmit() {
      const isAiPremium = this.mode !== 'premium';
      const planId = isAiPremium
        ? this._aiSelectedPlan.id
        : this._defSelectedPlan.id;

      this.isSubmitting = true;
      this.formErrors = null;
      
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const email = this.$refs.xemail.value;
      const password = this.$refs.xpassword.value;

      if (!emailRegex.test(email)) {
        this.formErrors = [["Invalid email address."]];
        this.isSubmitting = false;
        return;
      }

      var resp = await this.acc.registerBeforeCheckout({
        email: email,
        password: password,
      });
      console.log('after register');

      this.formErrors = resp.errors;

      if (resp.success) {
        console.log('resp.success', resp.success);

        analitics.sendCustomEvent('sign_up');
        this.$store.state.USER = resp.data.user;
        if (resp.data.user) {
          window.sendinblue.track('signup', { singupType: 'upgrade' });
          window.sendinblue.identify(resp.data.user.email, {
            STATUS: 'abandoned',
          });
          document
            .querySelector('meta[name="uniq_id"]')
            .setAttribute('content', resp.data.user.id);
          window.sendinblue.track('checkout', { checkoutType: 'after_signup' });
          window.location.replace(
            window.location.origin + `/upgrade-now?plan=${planId}`
          );
        }
      } else {
        this.isSubmitting = false;
      }
    },
    showLoginPopUp() {
      this.closeAllPopups();
      this.$store.dispatch('popupsToggle', {
        property: 'signIn',
        value: true,
      });
    },

    changePopup(property, value) {
      clearInterval(this.myInterval);
      this.$store.dispatch('popupsToggle', { property, value });
    },

    closeAllPopups() {
      this.$store.dispatch('popupsToggle', {
        property: 'stripeCheckoutPopup',
        value: false,
      });
    },

    changePeriod(period) {
      this.period = period;
    },

    getMonthlyPrice(isAiPremium = false) {
      const monthlyPlan = this._cartoonize_plans
        .filter(plan => plan.interval === 'month')
        .find(plan => plan.ai_plan === (isAiPremium ? 1 : 0));
      
      if (!monthlyPlan) return ''
      return monthlyPlan.price
    },
  },
  computed: {
    _cartoonize_plans() {
      return window.cartoonize_plans || [];
    },

    _intervalPlan() {
      return this.period === 'monthly' ? 'month' : 'year';
    },
    _plansByPeriod() {
      return this._cartoonize_plans.filter(
        (plan) => plan.interval === this._intervalPlan
      );
    },
    _aiSelectedPlan() {
      return this._plansByPeriod.find((q) => q.ai_plan === 1);
    },
    _defSelectedPlan() {
      return this._plansByPeriod.find((q) => q.ai_plan === 0);
    },
    listAiItems() {
      return this.$store.state.listAiItems;
    },

    allowAiTools() {
      return this.$store.state.allowAiTools;
    },

    _user() {
      return this.$store.state.USER;
    },
    currentPlanTerm() {
      return this._user?.current_plan?.interval;
    },
    isPremium() {
      return this._user?.isPremium;
    },
    upgradeText() {
      if (this.isPremium) {
        if (this.period === 'yearly') {
          if (this._user ?.current_plan?.interval == 'month') {
            return 'Upgrade to Yearly'
          }
          else{
            return 'Current plan'
          }
        }
        else{
          if (this._user ?.current_plan?.interval == 'month') {
            return 'Current plan'
          }
          else{
            return 'Downgrade'
          }
        }
      }
      return 'Upgrade Now';
      
    },

    isLoggedIn() {
      return this.$store.state.USER;
    },
  },
};
</script>

<style scoped>
  .text-strike {
    position: relative;
    color: #999;
  }

  .text-strike::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 2px;
    background-color: currentColor;
    transform: translateY(-50%);
  }
.fix_ai_top_list {
  top: 1.4rem;
  bottom: auto !important;
}

.disabled {
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.teaser-modal__image.cc {
  display: flex !important;
  width: 420px !important;
  height: 100% !important;
  position: absolute !important;
}

.reg-form .form_loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #8080809c;
  z-index: 10000;
}

#img-area {
  flex: 0 0 40%;
  max-width: 40%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/prem_before.jpg');
  background-size: cover, auto;
  background-position: center;
  transition: opacity 1s ease-in-out;
  z-index: -1;
  border-radius: 0 6px 6px 0;
}

#img-area2 {
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/prem_after.jpg');
  background-size: cover, auto;
  background-position: center;
  transition: opacity 1s ease-in-out;
  z-index: -1;
  border-radius: 0 6px 6px 0;
}

#img-area-register {
  flex: 0 0 50%;
  max-width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/mask.jpg');
  background-size: cover, auto;
  background-position: center;
  transition: opacity 1s ease-in-out;
  z-index: -1;
  border-radius: 0 6px 6px 0;
}

#img-area-register2 {
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/mask2.png');
  background-size: cover, auto;
  background-position: center;
  transition: opacity 1s ease-in-out;
  z-index: -1;
  border-radius: 0 6px 6px 0;
}

.already-member {
  text-align: left;
  font: normal normal 300 14px/20px Soleil;
  letter-spacing: -0.28px;
  color: #727272;
}

.popup.save_popup.upgrade-popup p.billing_term {
  letter-spacing: -0.64px;
  text-align: left;
  font-weight: normal;
  font-family: Soleil;
  margin-bottom: 15px;
  font-size: 14px;
}

.list-plus li {
  margin-top: 6px;
  text-align: left;
  font: normal normal 300 15px/20px Soleil;
  letter-spacing: -0.32px;
  color: #727272;
  opacity: 1;
  display: flex;
  align-items: center;
}

.list-plus li:before {
  content: url('../../../assets/svg/plus.svg'); /* FontAwesome Unicode */
  font-family: icomoon;
  /* color: #fa9d1c; */
  display: inline-block;
  /* margin-left: -20px; */
  margin-right: 5px;
  width: 1.3em; /* same as padding-left set on li */
}

.list-plus.list-plus--ai li:before {
  content: url('../../../assets/svg/plus-ai.svg');
}

/* .list-plus.list-plus--ai {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
} */

.double--column {
  margin-top: -8px;
  display: flex;
  justify-content: space-between;
  /* padding-left: 8px; */
}

.double--column .list-plus.list-plus--ai {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.double--column .list-plus.list-plus--ai li {
  margin-top: 8px;
  height: 18px;
  width: 173px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-sizing: border-box;
}
.double--column .list-plus.list-plus--ai li.f-width {
  width: 100%;
}

.content {
  flex: 0 0 50%;
  max-width: 50%;
  position: absolute;
  /*left:0;*/
  /*top:0;*/
  width: 100%;
  height: max-content;
}

.content-section {
  margin-top: 10px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup.save_popup.upgrade-popup {
  text-align: unset !important;
  padding: 0px 35px 0px 40px !important;
  max-width: 100%;
  width: 1060px;
  height: 640px;
  overflow: visible;
}
.popup.save_popup.upgrade-popup.upgrade-popup--small {
  width: 840px;
  height: 642px;
}
.popup.save_popup.upgrade-popup.reg-var {
  padding: 0px 35px 0px 36px !important;
}
.form_errors {
  text-align: left;
  font: normal normal normal 14px/34px Soleil;
  color: #ff5353;
}
.plus-benefits {
  position: absolute;
  bottom: 1.4rem;
  right: 0;
  background: #fff;
  width: 47%;
  padding: 20px;
}
.reg-var .list-plus li {
  margin-top: 8px;
  height: 18px;
}
.reg-var .list-plus li:before {
  vertical-align: middle;
  margin-top: -2px;
}
.agree-links {
  text-align: left;
  font: normal normal normal 12px/16px Soleil;
  letter-spacing: 0px;
  color: #828c9c;
  margin-top: 20px;
}
.agree-links a {
  text-decoration: underline;
}
.reg-form input {
  box-shadow: 0px 1px 2px #00000014;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  opacity: 1;
  width: 100%;
}

.form_loading {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.all-plus-future {
  text-align: left !important;
  font: normal normal 300 16px/20px Soleil !important;
  letter-spacing: -0.32px !important;
  color: #727272 !important;
  opacity: 1;
}

.popup.save_popup.upgrade-popup {
  padding: 70px 100px 60px 100px;
}

.decoration {
  text-decoration: line-through;
}

.upgrade-popup {
  transition: 0.2s;
}
.upgrade_mobile {
  width: 100%;
  max-width: 400px;
}
.upgrade_mobile .content {
  max-width: 100% !important;
  display: block!important;
  position: relative;
  margin: auto;
}
.upgrade_mobile .content-section {
  display: block!important;
}
.upgrade_mobile .toggles-for-period{
  width: auto!important;
}
.upgrade_mobile .popup.save_popup.upgrade-popup.subs_popup {
  width: auto;
  border-radius: 6px;
}
.upgrade_mobile .plus-benefits {
  position: relative;
  bottom: -6px;
  right: 0;
  background: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: none;
}
.upgrade_mobile .plus-benefits p {
  text-align: center;
  text-transform: uppercase;
}

.radio-group {
  position: relative;
  display: flex;
  flex-direction: column;
}

.radio-input {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 20px;
}

.radio-circle {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  transition: border-color 0.2s ease;
}

.radio-input:checked + .radio-label .radio-circle {
  border-color: #fa6400;
}

.radio-input:checked + .radio-label .radio-circle::after {
  content: '';
  width: 14px;
  height: 14px;
  background-color: #fa6400;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radio-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #1d1d1d;
  font-weight: normal;
}

.price-text {
  font-size: 24px;
}
</style>
