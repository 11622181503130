<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="editPanelPropChange">
      <div class="back">
          <a href="#" class="back-icon">
              <span class="inch-icon inch-back-icon"></span>
          </a>

          <h3>Background Removal</h3>
      </div>

      <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
          <img :src="helpIcon" alt/>
      </div>

      <TooltipAI
        :list="['Easily extract the subject from an image by removing the background.']"
        filename="Background_Removal"
      />
    </div>

    <div v-bar style="height: 568px;">
      <div class="text_panel_scroll">
          <div class="panel_row">
              <a href="#" class="blue_btn full_w_btn" @click="applyIt">
                  Remove Background
              </a>
          </div>

          <!-- <div class="panel_row">
            <ColorInput :defColor="payload.background"
              @input="changeRange('background')($event)"
              textLabel="Background Color"
              :checkmarkDisabled="true"
            />
          </div>

          <div class="panel_row">
              <Range
                  rangeSliderId="opacity"
                  :rangeSliderValue="payload.opacity"
                  rangeSliderLabel="Opacity"
                  rangeSliderClass="range-blue"
                  :minValue="0" :stepSlider="1"
                  :maxValue="100" :defaultValue="0"
                  @dragEnd="changeRange('opacity')($event)"
                  :changeValue="changeRange('opacity')"
                  rangeSliderMeasure="%"
              />
          </div> -->
      </div>

      <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
    </div>

    <TooltipAI
      :list="['Easily extract the subject from an image by removing the background.']"
      filename="Background_Removal"
    />
  </div>
</template>

<script>
import BaseComponent from './BaseComponent.js';

export default {
  mixins: [BaseComponent],
  data: function() {  
    return {
      payload: {
        opacity: 100,
        background: '#000000'
      },
    };
  },

  computed: {
      toolApi() {
          return this.$aitool.tools.BackgroundRemovalAPI;
      }
  },

  methods: { }
};
</script>
