<template>
	<header class="header" :class="[{'header_mobile' : isMobileView}, headerAppendClass]" v-click-outside="closess">
		<div v-if="isMobileView"
				class="toggler"
				v-on:click="toggleMenu"
				>
			<span class="header_logo">
				<img src="@/assets/mobile/menu.svg" alt />
			</span>
				</div>
					<div v-if="!$store.state.isInitiateApp" class="hover_dropdown_menu_sidebar" v-bind:class="togglerMenuActive ? 'show_sidebar' : 'hide_sidebar'">
						<div>
			<span class="inch-icon cancel_btn"><img @click="toggleMenu" :src="cancelIcon" alt="" /></span>
			<ul class="hover_dropdown_menu_list">
			  <li v-if="!isLoggined">
				<a href="/login" v-on:click.prevent="changePopup('signIn', true)">Sign In</a>
			  </li>
			  <li v-if="!isLoggined && !isPremiumAccount">
				<a href="/register" v-on:click.prevent="changePopup('signUp', true)">Register</a>
			  </li>

			  <li>
				<a :href="$store.state.domain + '/support/'">Help & Support</a>
			  </li>

			  <li v-if="isLoggined">
				<a @click="logout()">Sign Out</a>
			  </li>
			</ul>
			<div v-if="!isPremiumAccount && (!is_integration)" class="upgrade_block">
								<span class="header_upgrade_block">
									<span class="inch-icon"><img :src="upgradeIcon" alt="" /></span>
									<span>Get Pro</span>
								</span>
			  <p>
				Upgrade to Pro to unlock all premium features & effects.
			  </p>
			  <p class="upgrade_link">
				<a v-on:click="upgradePlan()">
				  <span class="upgrade_title">UPGRADE NOW</span><span> <img :src="rightArrowIcon" alt="" /> </span>
				</a>
			  </p>
			</div>
			<a class="go_back_btn" :href="$store.state.domain">
			  <span class="left_arrow_back_btn"><img :src="leftArrowIcon" alt="" /></span> BACK TO HOME
			</a>
		  </div>

					</div>

				<div class="left" v-if="!isMobileView">
					<a v-if=client_logo @click="openColorcinch()" class="header_logo" target="_parent" style="width: 200px; display: block;">
						<img :src=client_logo alt />
					</a>

					<a v-if=!client_logo @click="openColorcinch()" class="header_logo">
						<img src="@/assets/img/logo.svg" alt />
					</a>

					<div
							v-if=!is_integration
							class="dropdown_button hover_dropdown_item stndrt_photo"
							v-bind:class="{ active: photoEditActive }"
							v-on:click.prevent="photoEditShow"
					>
					<span>
						Standard Photo Editor
						<ArrowDown />
					</span>
						<ul v-if=!is_integration class="hover_dropdown_list">
							<li>
								<a @click="openStandartEditor()">Standard Photo Editor</a>
							</li>
							<li>
								<a @click="openAvatarMaker()">Avatar Maker</a>
							</li>
						</ul>
					</div>
				</div>

	  <div class="left" v-if="isMobileView && headerAppendClass=='enabled'">
		<a @click="changePopup('resetPopup', true)" class="header_logo reset_icon">
		  <img src="@/assets/mobile/reset.svg" alt="Reset" />
		</a>

		<a @click="saveOnMobile()" class="header_logo">
		  <img src="@/assets/mobile/save-arrow.svg" alt="Save" />
		</a>
	  </div>

				<ul v-if="!isMobileView" class="header_center_menu">
					<div v-click-outside.prevent="outside" :class="{ 'share-social-show': share_displayed }" class="share-social open" style="position: absolute; left: calc(50% - 65px);">
						<a class="share-social__item">
							<img @click="share('facebook')" src="new_images/facebook.svg" alt="">
						</a>
						<a class="share-social__item" v-if="false">
							<img @click="share('facebook')" src="new_images/insta@2x.png" alt="">
						</a>
						<a class="share-social__item">
							<img @click="share('twitter')" src="new_images/twitter.svg" alt="">
						</a>
						<a class="share-social__item">
							<img @click="share('pinterest')" src="new_images/pinterest.svg" alt="">
						</a>
					</div>

					<li>
						<div
								tabIndex="0"
								class="dropdown_button hover_dropdown_item"
								v-bind:class="{ active: openActive }"
								v-on:click="openShow"
						>
							Open
							<ArrowDown />
							<ul class="hover_dropdown_list">
								<li>
									<a @click="selectImageToOpen">
										<span>My Computer</span>
									</a>
								</li>
								<li v-if=!is_integration>
									<a v-on:click="changePopup('projectManager', true)">
										Open Project
										<span
												v-if="!isPremiumAccount"
												class="inch-icon inch-plus-membership-icon"
										></span>
									</a>
								</li>

								<li>
									<a v-on:click="openGoogleDrive" style="justify-content: flex-start;">
										<span style="top: 102px;left: 834px;width: 25px;height: 16px;background: transparent url(/new_images/logo_drive.png) 0% 0% no-repeat padding-box;opacity: 1;"></span>
										Google Drive™
									</a>
								</li>
							</ul>
							<input
									v-custom-upload
									style="display: none;"
									data-type="0"
									id="userFile"
									type="file"
									ref="file"
									accept="image/*"
									@change="handleFileChange"
							/>
						</div>
					</li>
					<li :class="srAppendClass">
						<div
								class="dropdown_button hover_dropdown_item"
								v-bind:class="{ active: saveActive }"
								v-on:click.stop="saveShow"
								tabIndex="0"
						>
							Save
							<span :style="{ opacity: !autosave.loading && autosave.isHaveChanges ? 1 : 0 }">*</span>
							<div class="saveLoad">
								<svg class="autosave_loading" style="margin: 0; padding: 0px"
									 :style="{
									 top: `${autosave.loading ? 3 : -20}px`,
										 opacity: `${autosave.loading ? 1 : 0}`
									}" fill="#249ACF" xmlns="http://www.w3.org/2000/svg"
									 viewBox="0 0 24 24" width="24px" height="24px">
									<path d="M 12 0 L 8 4 L 12 8 L 12 5 C 15.859 5 19 8.14 19 12 C 19 12.88 18.82925 13.720094 18.53125 14.496094 L 20.046875 16.009766 C 20.651875 14.800766 21 13.442 21 12 C 21 7.038 16.963 3 12 3 L 12 0 z M 3.953125 7.9902344 C 3.348125 9.1992344 3 10.558 3 12 C 3 16.962 7.037 21 12 21 L 12 24 L 16 20 L 12 16 L 12 19 C 8.141 19 5 15.86 5 12 C 5 11.12 5.17075 10.279906 5.46875 9.5039062 L 3.953125 7.9902344 z"/>
								</svg>

								<ArrowDown class="arrow_autosave" :style="{ padding: 0, opacity: `${autosave.loading ? 0 : 1}`, top: `${autosave.loading ? 21 : 9}px`}"/>
							</div>

							<ul class="hover_dropdown_list">
								<li>
									<a v-on:click="saveTo('saveToComputer', true)">My Computer</a>
								</li>

								<li>
									<a v-on:click="saveGoogleDrive"  style="justify-content: flex-start;">
										<span style="top: 102px;left: 834px;width: 25px;height: 16px;background: transparent url(/new_images/logo_drive.png) 0% 0% no-repeat padding-box;opacity: 1;"></span>
										Google Drive™
									</a>
								</li>
								<li>
									<a v-on:click.stop="showShare">Share</a>
								</li>

								<li v-if=!is_integration>
									<a v-on:click="saveTo('SaveAsProject', true)">
										Save as Project
										<span
												v-if="!isPremiumAccount"
												class="inch-icon inch-plus-membership-icon"
										></span>
									</a>
								</li>

							</ul>
						</div>
					</li>
					<li :class="srAppendClass">
						<a tabIndex="0" v-on:click="changePopup('resetPopup', true)" :style="{ padding: '18px 24px' }">Reset</a>
					</li>
				</ul>

				<div v-if="!isMobileView" class="right">
					
					<template v-if="(!aiTools && !isCustomLocation) && aiUsage !== false && aiUsage.length > 1">
						<span class="trial-badge"> AI Credits used: {{ aiUsage }} </span>
					</template>

					<template v-if="trialLeft !== false && trialLeft >= 0">
						<span class="trial-badge"> {{ trialLeft }}-day AI trial left </span>
					</template>
					<template v-else>
						<a class="blue_btn" v-if="(!isPremiumAccount && (!is_integration)) || (!aiTools && !isCustomLocation) || isTrialUser" v-on:click="upgradePlan()"
						:key="`upd_${aiTools}_${isCustomLocation}_${isPremiumAccount}`">
							{{ (!aiTools && isPremiumAccount || isTrialUser) ? 'Upgrade to Total AI' : 'Upgrade'}} 
						</a>
					</template>

					<div
							v-if=!is_integration
							class="humb_btn hover_dropdown_item"
							v-bind:class="{ active: humbActive }"
							v-on:click="humbShow"
							:style="getThumbStyle(isLoggined)"
					>
						<HumbIcon v-if="!isLoggined"/>
						<span v-if="isLoggined" v-tooltip="{ content:'My Account' }" class="icon-loggined-user">{{ getFirstLetterUsername }}</span>
						<svg v-if="isLoggined" xmlns="http://www.w3.org/2000/svg" width="9" height="5" class>
							<path
									d="M5.04 4.841a.574.574 0 01-.4.159h-.282a.59.59 0 01-.4-.159L.111 1.128a.351.351 0 010-.512L.644.104a.378.378 0 01.525 0l3.329 3.214L7.829.106a.386.386 0 01.533 0l.525.512a.351.351 0 010 .512z"
									fill="#8c8c8c"
							/>
						</svg>
						<ul class="hover_dropdown_list">
							<li v-if="!isLoggined">
								<a href="/login" v-on:click.prevent="changePopup('signIn', true)">Sign In</a>
							</li>
							<li v-if="!isLoggined">
								<a href="/register" v-on:click.prevent="changePopup('signUp', true)">Register</a>
							</li>
			<li v-if="isLoggined">
								<a
										href="/my-account">My Account</a>
							</li>

							<li>
								<a v-on:click="changePopup('projectManager', true)" v-if="isLoggined">
									My Projects
									<span v-if="!isPremiumAccount" class="inch-icon inch-plus-membership-icon"></span>
								</a>
							</li>

							<li>
								<a
										v-on:click="changePopup('suggestFeature', true)">Suggest a Feature</a>
							</li>
							<li>
								<a
										:href="$store.state.domain + '/support/'">Help & Support</a>
							</li>

							<li v-if="isLoggined">
								<a @click="logout()">Sign Out</a>
							</li>
							<li class="humb_mob_li dropdown_button">
								Standard Photo Editor
								<ArrowDown />
								<ul class="hover_dropdown_list">
									<li>
										<a href>Standard Photo Editor</a>
									</li>
								</ul>
							</li>

							<li class="humb_mob_li dropdown_button">
								Open
								<ArrowDown />
								<ul class="hover_dropdown_list">
									<li>
										<router-link to="/image-manager">
											<span>My Computer</span>
										</router-link>
									</li>
									<li v-if=!is_integration>
										<a v-on:click="changePopup('projectManager', true)">
											Open Project 1
											<span class="inch-icon inch-plus-membership-icon"></span>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
				<!-- <div class="menu-closer" v-if="openedMenu" @click="closeAll"></div> -->

			</header>

</template>

<script>

// import Logo from 'assets/img/logo.svg';
import ArrowDown from "@/assets/img/arrow_down.svg?inline";
import HumbIcon from "@/assets/img/ham_icon.svg?inline";
import googleDrive from '../libs/googleDrive';
import saveAs from 'file-saver';
import { EventBus } from '@/main.js';
window.googleDrive = googleDrive;

export default {
name: "Header",
components: {
	ArrowDown,
	HumbIcon
},
props: {
	srAppendClass: String,
	headerAppendClass: String
},
data: function() {
	return {
		isMobileView: false,
		upgradeIcon: require("@/assets/mobile/plus.svg"),
		cancelIcon: require("@/assets/mobile/cancel-white.svg"),
		leftArrowIcon: require("@/assets/mobile/arrow_left.svg"),
		rightArrowIcon: require("@/assets/mobile/arrow_right.svg"),
		is_pwa: false,
		client_logo: window.client_logo,
		client_domain: window.client_domain,
		is_integration: window.is_integration,
		photoEditActive: false,
		openActive: false,
		saveActive: false,
		humbActive: false,
		togglerMenuActive: false,
		openedMenu: false,
		isPremium: false,
		userFileSelected: null,
		saveToSelected: false,
		share_displayed: false,
	};
},
created() {
	if (this.$store.state.isMobileView) {
		EventBus.$on("save-on-mobile", this.onShare);
	}
},
methods: {
async onShare() {
  let thisObj = this;
  setTimeout(async function () {
	let blobResult = await thisObj.$lib.app.getExportImage('jpeg', 80),
		fileName = "download.jpeg",
		file = new File([blobResult], fileName, {type: 'image/jpeg'}),
		title = document.title,
		dateTime = new Date().toLocaleString().replace(", ", "_").replaceAll("/", "-").slice(0, -3),
		text = "colorcinch_" + dateTime;

	if (navigator.share) {
	  try {
		navigator.share({
		  text: text,
		  files: [file],
		  title: title,
		  url: document.location.href
		}).catch(error => {
		  // this will catch the second share attempt
		  console.log(`Couldn't share.. ${error}`);
		  saveAs(blobResult, fileName);
		});
	  } catch (err) {
		console.log(`Couldn't share ${err}`);
		saveAs(blobResult, fileName);
	  }
	} else {
	  console.log(`Not supported !!`, blobResult, fileName);
	  saveAs(blobResult, fileName);
	}


	thisObj.$store.state.saveOnMobile = false;
  }, 2000);
},

async saveOnMobile() {
	this.$store.state.saveOnMobile = true;
  if (this.getPremiumInfoStatus && !(await this.$store.dispatch("isPremiumCurrentUser")))
  {
	// if (window.abVariant && window.abVariant == 2) {
			this.$store.dispatch("popupsToggle", {
				property: "stripeCheckoutPopup",
				value: true,
			});
		// }
		// else{
		// 	this.$store.dispatch("popupsToggle", {
		// 		property: "upgradePopup",
		// 		value: true,
		// 	});
		// }
	return;
  }
  this.$store.state._applySelectedEffect += 1;

  // this.$lib.app.downloadImageByBlob(
  //     blobResult,
  //     'download',
  //     'jpeg'
  // );
},

	toggleMenu() {
  this.$emit('sidebarMenu', !this.togglerMenuActive);
		return (this.togglerMenuActive = !this.togglerMenuActive);
	},
	closess(event) {
		this.closeAll();
	},

	outside(ev) {
		this.share_displayed = false;
	},

	qq() {
		this.photoEditActive = false;
	},

	async saveGoogleDrive() {
		if(!this.getPremiumInfoStatus && this.isFreeUser && !this.isLoggined) {
			// this.$store.state.popups.signUp = true;
			this.changePopup('signUp', true);
		} else if(this.getPremiumInfoStatus){
			// if (window.abVariant && window.abVariant == 2) {
				this.changePopup("stripeCheckoutPopup", true);
			// }
			// else{
			// 	this.changePopup('upgradePopup', true);
			// }
		} else {
			const property = 'googleDrive'
			const value = true
			// this.saveToSelected = {property, value};
			// this.$store.state._applySelectedEffect += 1;
			// this.$store.state.popups.saveToComputer = { type: 'googleDrive' };
			const panelName = (this.$store.state.sidebarPanel.panelName || '').toLowerCase();
			const isOpenPanel = ['Remove Background', 'Effects', 'Filters', 'Overlays', 'Masks', 'Frames', 'Draw']
					.find(panel => panelName == panel.toLowerCase());


			const isEditPanel = this.$store.state.selectedEditPanel && this.$store.state.sidebarPanel.panelName == 'Edit';

			if(!isOpenPanel && !isEditPanel) {
				this.$store.state.popups.saveToComputer = { type: 'googleDrive' };
			} else {
				this.saveToSelected = { property, value };
				this.$store.state._applySelectedEffect += 1;
			}


		}
	},


	async showShare(){
		if(!this.getPremiumInfoStatus && this.isFreeUser && !this.isLoggined) {
			// this.$store.state.popups.signUp = true;
			this.changePopup('signUp', true);
		} else if(this.getPremiumInfoStatus){
			// if (window.abVariant && window.abVariant == 2) {
				this.changePopup("stripeCheckoutPopup", true);
			// }
			// else{
			// 	this.changePopup('upgradePopup', true);
			// }
			
		} else {
			this.closeAll();
			this.share_displayed = !this.share_displayed;
		}
	},
	async share(type="facebook") {
		this.$store.dispatch("preloader", { isShow: true, message: 'Sharing...' });

		try {
			this.share_displayed = false;
			let app_id = '266773882220830'; //"867379440608053"
			let blob = await fetch(_LIB.app.extractOptimizedThumb(512, 512)).then(r => r.blob());
			//await fetch(await _LIB.app.extractStage('base64', { isFull: true })).then(r => r.blob());
			let size = _LIB.app._canvasSize;

			let file = new File([blob], "file.jpeg");

			let data = new FormData();
			data.append('file', file);
			data.append('_token', document.querySelector("meta[name='csrf-token']").content);

			data.append('width', _LIB.app._canvasSize.w);
			data.append('height', _LIB.app._canvasSize.h);

			let resp = await fetch('/api/share',{
				method: 'POST',
				body: data,
				mode: 'no-cors'
			}).then((res)=>{ return res.json() });
			let description = '';
			let link = false;

			switch(type){
				case 'facebook':
					link  = `https://www.facebook.com/dialog/share?app_id=${app_id}&display=popup&href=${resp.url}`;
				break;
				case 'pinterest':
					link = `http://pinterest.com/pin/create/link/?url=${resp.url}`;//&description=${description}&media=${resp.url}&method=button`
					//link = `https://www.pinterest.com/pin-builder/?url=${resp.url}&description=${description}&media=${resp.url}&method=button`
				break;
				case 'twitter':
					link = `https://twitter.com/intent/tweet?url=${resp.url}&text=via+@colorcinch`;
				break;
			}

			window.open(link, "Share", "width=300,height=200");
		} catch(err) {
			console.error(err);
		}

		this.$store.dispatch("preloader", { isShow: false });
	},

	async openGoogleDrive() {
		if(!googleDrive.inited) await googleDrive.init();

		this.$store.state.preloader = { message: 'Loading...', isShow: true };
		 googleDrive.getFile().then(file => {
			if(file) this.handleFileChange({ target: { files: [file] } });
			this.$store.state.preloader = false;
		});
	},

	upgradePlan() {
		// if (window.abVariant && window.abVariant == 2) {
			this.changePopup("stripeCheckoutPopup", true);
		// }
		// else{
		// 	this.changePopup("upgradePopup", true);
		// }
	},

	async openStandartEditor(isConfirm = false) {
		if (!isConfirm && this.projectIsOpen()) {
			this.$store.dispatch("confirmPopUpConfig", {
				isHide: false,
				popupTitle: "RESET PROJECT?",
				popupBody:
					"Are you sure you would like to continue? Any unsaved changes will be lost.",
				name: "openStandartEditor"
			});
		} else {
			window.location.reload();
		}
	},
	async openAvatarMaker(isConfirm = false) {
		if (!isConfirm && this.projectIsOpen()) {
			this.$store.dispatch("confirmPopUpConfig", {
				isHide: false,
				popupTitle: "RESET PROJECT?",
				popupBody:
					"Are you sure you would like to continue? Any unsaved changes will be lost.",
				name: "openAvatarMaker"
			});
		} else {
			window.location.href = "https://www.cartoonize.net/avatar-maker/"
		}
	},

	async openColorcinch(isConfirm = false) {
		if (!isConfirm && this.projectIsOpen()) {
			this.$store.dispatch("confirmPopUpConfig", {
				isHide: false,
				popupTitle: "EXIT APPLICATION?",
				popupBody:
					"Are you sure you would like to exit the application? Any unsaved changes will be lost.",
				name: "openColorcinch"
			});
		} else {
			if(this.is_integration){
				window.parent.location = `https://${client_domain}`;
			}else{
				window.open(window.locationOrigin, '_blank');

//					window.location.href = `${window.locationOrigin}`;
			}
		}
	},

	projectIsOpen() {
		return (
			this.$lib &&
			this.$lib.app &&
			this.$lib._cache.getHistoryChanges().length
		);
	},

	async logout(logOut = false) {
  this.togglerMenuActive = true;
  this.toggleMenu();
		let confirm = !!logOut;

		if (!this.projectIsOpen()) {
			confirm = true;
		} else if (!logOut) {
			this.$store.dispatch("confirmPopUpConfig", {
				isHide: false,
				popupTitle: "SIGN OUT?",
				popupBody:
					"Are you sure you would like to continue? Any unsaved changes will be lost.",
				name: "signOut"
			});
			return;
		}

		if (confirm) {
			if(navigator.onLine){
				await fetch(`${this.$store.state.prefixURL}/logout3`);
			}else{
				let cache = await caches.open("cartoonize-cache");
				await cache.delete("user");
			}
			this.$store.state.USER = {};

			window.location.reload();
		}
	},

	getThumbStyle(isLoggined) {
		if (!isLoggined) return {};

		return { width: "85px" };
	},

	selectImageToOpen() {
		this.$refs.file.click();
	},

	async handleFileChange(e) {
		this.userFileSelected = e.target.files[0];

		if (this.projectIsOpen()) {
			if (this.userFileSelected) {
				this.$store.dispatch("confirmPopUpConfig", {
					isHide: false,
					popupTitle: "RESET PROJECT?",
					popupBody:
						"Are you sure you would like to continue? Any unsaved changes will be lost when you upload a new photo.",
					name: "loadImage"
				});
			}
		} else {
			this.uploadFileFromComputer();
		}

	 // this.$refs.file.value = null;
	},

	saveTo(property, value) {
		const panelName = (this.$store.state.sidebarPanel.panelName || '').toLowerCase();
		const isOpenPanel = ['Remove Background', 'Effects', 'Filters', 'Overlays', 'Masks', 'Frames', 'Draw']
			.find(panel => panelName == panel.toLowerCase());


		const isEditPanel = this.$store.state.selectedEditPanel && this.$store.state.sidebarPanel.panelName == 'Edit';

		if(this.isFreeUser && !this.isLoggined){
			this.showPopupsForFreeUser(property, value)
			return
		}


		if(!isOpenPanel && !isEditPanel) {
			this.changePopup(property, value);
		} else {
			this.saveToSelected = { property, value };
			this.$store.state._applySelectedEffect += 1;
		}
	},

	showPopupsForFreeUser(property, value){
		const premiumPopups = ['SaveAsProject'];
		if(premiumPopups.includes(property)) {
			property = 'SaveAsProject';
		}
		else if(this.getPremiumInfoStatus) {
			// if (window.abVariant && window.abVariant == 2) {
				return this.$store.dispatch("popupsToggle", {
					property: "stripeCheckoutPopup",
					value: true
				});
			// }
			// else{
			// 	return this.$store.dispatch("popupsToggle", {
			// 		property: "upgradePopup",
			// 		value: true
			// 	});
			// }
			
		} else {
			property = 'signUp';
		}
		// this.$store.state.popups[property] = value
		// this.$store.dispatch("popupsToggle", { property, value });
		return this.changePopup(property, value)
	},

	async changePopup(property, value) {
  this.togglerMenuActive = true;
  this.toggleMenu();
		if (
			["projectManager", "SaveAsProject"].find(v => v == property) &&
			!(await this.$store.dispatch("isPremiumCurrentUser"))
		) {
			// if (window.abVariant && window.abVariant == 2) {
				return this.$store.dispatch("popupsToggle", {
					property: "stripeCheckoutPopup",
					value: true
				});
			// }
			// else{
			// 	return this.$store.dispatch("popupsToggle", {
			// 		property: "upgradePopup",
			// 		value: true
			// 	});
			// }
		}

		this.$store.dispatch("popupsToggle", { property, value });

	},

	photoEditShow() {
		this.share_displayed = false;
		this.closeAll();
		this.openedMenu = true;
		return (this.photoEditActive = !this.photoEditActive);
	},

	openShow() {
		this.share_displayed = false;
		this.closeAll();
		this.openedMenu = true;
		return (this.openActive = !this.openActive);
	},

	async saveShow() {
		this.share_displayed = false;
		this.closeAll();
		this.openedMenu = true;
		return (this.saveActive = !this.saveActive);
	},

	humbShow() {
		this.closeAll();
		this.openedMenu = true;
		return (this.humbActive = !this.humbActive);
	},

	closeAll() {
		this.photoEditActive = false;
		this.openActive = false;
		this.saveActive = false;
		this.humbActive = false;
		this.openedMenu = false;
	},

	uploadFileFromComputer() {
		if (this.userFileSelected) {
			this.$store.dispatch("userFile", this.userFileSelected);
			this.userFileSelected = null;
			if (this.$route.name == "home") {
				this.$router.push({
					path: this.$store.state.routerPrefixURL + "/image-manager",
					hash: this.$route.hash
				});
				// this.$router.push(this.$store.state.routerPrefixURL + "/image-manager");
			}
			this.closeAll();
		}
	}
},

async mounted() {
	this.isMobileView = this.$store.state.isMobileView;
	this.is_pwa = window.matchMedia('(display-mode: standalone)').matches && typeof PaymentRequest!='undefined';
	this.isPremium = await this.$store.dispatch("isPremiumCurrentUser");
},

computed: {
	isCustomLocation() {
		const isLocation = ['IN', 'BR', 'ID'].includes(window.subLocation);
		return isLocation;
	},

	allowAiTools() {
		return this.$store.state.allowAiTools;
	},

	getPremiumInfoStatus() {
		return this.$store.state.premiumStatus;
	},
	autosave() {
		return this.$store.state.autosave;
	},

	_callbackSave() {
		return this.$store.state._callbackSave;
	},

	contextEvent() {
		return this.$store.state.contextEvent;
	},
	aiUsage() {
		if(!this.USER){
			return false;
		}
		if (!this.USER.aiUsageConfig || this.USER.aiUsageConfig === 0) {
			return false;
		}
		return this.USER.aiUsage + ' / ' + this.USER.aiUsageConfig;
	},

	trialLeft() {
		if(!this.USER){
			return false;
		}
		return this.USER.trialDaysLeft;
	},

	isTrialUser() {
		if(!this.USER){
			return false;
		}
		return !!this.USER.is_trial_user;
	},

	aiTools() {
		if(!this.USER){
			return false;
		}
		return this.USER.aiTools;
	},

	USER() {
		return this.$store.state.USER;
	},

	isFreeUser() {
		return parseInt(this.$store.state.INFO?.is_free_users) ?? 0;
	},

	getFirstLetterUsername() {
		return ((this.$store.state.USER || {}).name || (this.$store.state.USER || {}).email || " ")[0];
	},

	isPremiumAccount() {
		return this.$store.state.isPremiumAccount;
	},

	getConfirmPopUpAnswer() {
		return this.$store.state.confirmPopUp.answer;
	},

	isLoggined() {
		const user = this.$store.state.USER;
		return user && typeof user.id == "number";
	},

	checkIsPremium() {
		return this.isPremium;
	}
},

watch: {
	'$store.getters.SETTINGS.enableAutosave':{
		handler(){
			console.log("header enableAutosave");
			this.autosave.isHaveChanges = this.$store.getters.SETTINGS.enableAutosave?this.autosave.isHaveChanges:false;
//				this.updateAutosave(this.$store.getters.SETTINGS.enableAutosave);
		}
	},

	isPremiumAccount(isPremium) {
		if(isPremium) {
			this.$lib.watermark.remove();
			this.$store.state.premiumStatus = false;
		}
	},

	_callbackSave() {
		if(this.$store.state.popups.upgradePopup || this.$store.state.popups.stripeCheckoutPopup) {
			this.saveToSelected = false;
			return;
		}

		if(this.saveToSelected) {
			const property = this.saveToSelected.property;
			const value = this.saveToSelected.value;

			this.saveToSelected = false;
			setTimeout(() => {
				console.log(property)
				if(property === 'googleDrive'){
					this.$store.state.popups.saveToComputer = { type: 'googleDrive' };
				} else {
					this.changePopup(property, value);
				}

			}, 100);
		}
	},

	contextEvent(eventName) {
		if(!eventName) return;

		this.$store.state.contextEvent = null;
		switch(eventName) {
			case 'openFromComputer':
				return this.selectImageToOpen();
			case 'saveToComputer':
				if(!this.getPremiumInfoStatus && this.isFreeUser && !this.isLoggined) {
					return this.changePopup('signUp', true);
				} else if(this.getPremiumInfoStatus){
					// if (window.abVariant && window.abVariant == 2) {
						return this.changePopup("stripeCheckoutPopup", true);
					// }
					// else{
					// 	return this.changePopup('upgradePopup', true);
					// }
				}
				return this.changePopup('saveToComputer', true);
			case 'reset':
				return this.changePopup('resetPopup', true);
		}
	},

	getConfirmPopUpAnswer(answer) {
		if (answer && answer.name && answer.result) {
			switch (answer.name) {
				case "loadImage": {
					this.uploadFileFromComputer();
					break;
				}

				case "signOut": {
					this.logout(true);
					break;
				}

				case "openColorcinch": {
					this.openColorcinch(true);
					break;
				}

				case "openStandartEditor": {
					this.openStandartEditor(true);
					break;
				}
				case "openAvatarMaker": {
					this.openAvatarMaker(true);
					break;
				}
			}
		}
	}
}
};
</script>

<style>
.trial-badge {
	border: 2px solid #4ddabb;
	color: #4ddabb;
	font-size: 12px;
	font-weight: bold;
	padding: 5px 10px;
	border-radius: 50px;
	white-space: nowrap;
	margin-right: 18px;
}
.share-social {
	display: none;
	padding: 6px 8px;
	background: rgb(255, 255, 255, 0.05);
	border-radius: 30px;
	opacity: 0;
	transform: translateY(-40%);
	pointer-events: none;
}

.share-social.open {
	display: inline-flex;
	opacity: 0;
	/* animation: social-appear 0.3s ease forwards;
	transform: translateY(40%); */
	top: 100px;
	pointer-events: none;
	z-index: 100
}

.share-social__item {
	width: 20px;
	height: 20px;
}

.share-social__item:not(:last-child) {
	margin: 0 17px 0 0;
}

.share-social__item img {
	opacity: 0.9;
	transition: all 0.3s;
}

.header{
	border-top: 1px solid rgba(112, 112, 112, 0.2);
}
.header .left,
.header .header_center_menu,
.header .right {
	position: relative;
	z-index: 100;
}

.menu-closer {
	position: fixed;
	z-index: 99;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
}

.icon-loggined-user {
	font-family: Proxima Nova;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
	width: 36px;
	height: 36px;
	color: rgb(255, 255, 255);
	border-radius: 50%;
	margin: 0px 10px;
	background: 0% 0% no-repeat padding-box padding-box rgb(255, 153, 30);
	text-align: center;
	padding-top: 9px;
}

.autosave_loading {
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	width: 15px;
	height: 15px;
	-webkit-animation:spin 4s linear infinite;
	-moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;
	position: absolute;
	top: -20px;
}

.autosave_loading path {
	fill: #249ACF !important;
}

@-moz-keyframes spin {
	100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform:rotate(360deg);
	}
}

.saveLoad {
	width: 20px;
	height: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
}

.saveLoad > * {
	transition: .5s;
}

.saveLoad svg {
	padding-bottom: 3px;
}

.arrow_autosave {
	position: absolute;
	top: 9px;
	left: -8px;
	padding: 0;
}

.share-social-show {
	top: 100px;
	-webkit-animation: slide .1s forwards;
	-webkit-animation-delay: .1s infinite;
	animation: slide .1s forwards;
	animation-delay: .1s;
	animation-iteration-count: 1;
	pointer-events: all !important;
}

@keyframes slide {
	100% { top: 85px; opacity: 1; }
	0% { top: 100px; opacity: 0; }
}

.toggler {
display: flex;
}
.hover_dropdown_item {
	position: relative;
	cursor: pointer;

}

.hover_dropdown_menu_sidebar {
width: calc(100% - 94px);
height: 100%;
	background: #0F0F0F;
	padding: 0 0 42px 32px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	box-shadow: 1px 1px 2px rgba(29, 29, 29, 0.7);
transition: transform 0.3s;
overflow-y: auto;
}
.cancel_btn {
	display: flex;
	justify-content: flex-end;
	padding: 8px 8px 0 0;
}
.hover_dropdown_menu_list {
	margin-top: 19px;
}
.hover_dropdown_menu_list li a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #8C8C8C;
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	line-height: 20px;
	padding: 0 9px 36px 0;
	width: 100%;
}
.upgrade_block {
	color: #FFFFFF;
	margin: 4px 32px 0 0;
	border-top: 1px solid rgba(112, 112, 112, 0.2);
	padding: 40px 0 0 0;
}
.header_upgrade_block {
	display: flex;
	font-size: 16px;
	line-height: 20px;
}
.header_upgrade_block span {
	margin-right: 12px;
}
.header_upgrade_block img {
	width: 16px;
	height: 14.7px;
}
.upgrade_block p {
	padding: 12px 0;
	color: #8C8C8C;
	font-size: 13px;
	line-height: 20px;
}
p.upgrade_link {
	padding: 0;
	margin-top: 8px;
	width: fit-content;
	color: #FA6400;
	border-bottom: 1px dotted #FA640066;
	text-transform: uppercase;
}
.upgrade_link .upgrade_title {
	margin-right: 8px;
}
.go_back_btn {
color: #D7DCDD;
font-size: 11px;
line-height: 13px;
position: absolute;
bottom: 0;
font-weight: 100;
}
.left_arrow_back_btn {
	margin-right: 8px;
}

.show_sidebar {
transform: translatex(0);
}
.hide_sidebar {
transform: translatex(-100%);
}
.left .reset_icon {
min-width: 40px;
width: 40px;
margin-right: 0px;
}
.header.header_mobile .left {
transition: all 0.3s;
position: fixed;
right: 0;
}
.sidebarToggle .header.header_mobile .left {
right: -50px;
opacity: 0.2
}
.hover_dropdown_menu_sidebar > div {
position: relative;
height: 100%;
}
@media (orientation: landscape) {
.hover_dropdown_menu_sidebar .upgrade_block {
  padding-bottom: 50px;
}
.hover_dropdown_menu_sidebar > div {
  height: auto;
}
}
</style>
