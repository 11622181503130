<template>
	<footer class="footer" :class="[{'footer_mobile' : isMobileView}, footerAppendClass]">
		<ZoomBlock v-if="!isMobileView"/>
		<div v-if="!isMobileView" class="right_btns_wrpr">
			<a
					href="#"
					v-on:click="showChangeByStep('prev')"
					class="btn-action footer_right_btn"
					v-tooltip="'Undo'"
			>
				<span class="inch-icon inch-undo-icon"></span>
			</a>
			<a
					href="#"
					v-on:click="showChangeByStep('next')"
					class="btn-action footer_right_btn"
					v-tooltip="'Redo'"
			>
				<span class="inch-icon inch-redo-icon"></span>
			</a>
			<a
					v-tooltip="'Layers'"
					@click="openLayers"
					class="btn-action footer_right_btn"
					:class="layersButton ? 'active' : ''"
			>
				<span class="inch-icon inch-layers-icon"></span>
			</a>
		</div>

		<transition name="fade">
			<div class="popup_wrpr active" v-if="popupState.projectManager">
				<ProjectManager :showLoader="showLoadingNotif"></ProjectManager>
				<div class="pop_up_closer" v-on:click="changePopup('projectManager', false)"></div>
			</div>
		</transition>

		<transition name="slide-fade-popup">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.saveToComputer">
				<SaveToComputer :savingThisProject="showLoadingNotif" :saveProjectImg="saveProjectImg" />
				<div class="pop_up_closer" v-on:click="changePopup('saveToComputer', false)"></div>
			</div>
		</transition>

		<transition name="slide-fade-popup">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.googleDrive">
				<SaveGoogleDrive/>
				<div class="pop_up_closer" v-on:click="changePopup('SaveGoogleDrive', false)"></div>
			</div>
		</transition>

		<transition name="slide-fade-popup">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.popupGraphics">
				<PopupGraphics />
				<div class="pop_up_closer" v-on:click="changePopup('popupGraphics', false)"></div>
			</div>
		</transition>

		<transition name="slide-fade-popup">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.popupUnsplash">
				<PopupUnsplash />
				<div class="pop_up_closer" v-on:click="changePopup('popupUnsplash', false)"></div>
			</div>
		</transition>

		<transition name="slide-fade-popup">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.SaveAsProject">
				<SaveAsProject
						:savingThisProject="showLoadingNotif"popup
						:saveProjectImg="saveProjectImg"
						saveAsProjectText="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet,"
				/>
				<div class="pop_up_closer" v-on:click="changePopup('SaveAsProject', false)"></div>
			</div>
		</transition>

		<transition name="slide-fade-popup">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.suggestFeature">
				<SuggestFeature
						:sendingFeedback="showLoadingNotif"
						suggestFeatureText="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor."
				/>
				<div class="pop_up_closer" v-on:click="changePopup('suggestFeature', false)"></div>
			</div>
		</transition>

		<transition name="fade">

			<!-- <div class="popup_wrpr active popup_up_down" v-if="popupState.upgradePopup && !showNewPopup && !isMobileView">
				<UpgradePopup :yesUpgrade="showLoadingNotif" :allowOnlyAnnual="allowOnlyAnnual" />
				<div class="pop_up_closer" v-on:click="changePopup('upgradePopup', false)"></div>
			</div> -->

			<div class="popup_wrpr active popup_up_down" v-if="popupState.congratsAiPopup && !showNewPopup">
				<CongratsAiPopup />
				<div class="pop_up_closer" v-on:click="changePopup('congratsAiPopup', false)"></div>
			</div>

			<div class="popup_wrpr active popup_up_down" v-if="popupState.socialLoginPopup && !showNewPopup">
				<SocialLoginPopup />
				<div class="pop_up_closer" v-on:click="changePopup('socialLoginPopup', false)"></div>
			</div>

			<div class="popup_wrpr active popup_up_down" v-if="popupState.trialPopup && !showNewPopup">
				<TrialPopup :yesUpgrade="showLoadingNotif" />
				<div class="pop_up_closer" v-on:click="changePopup('trialPopup', false)"></div>
			</div>
			
			<div class="popup_wrpr active popup_up_down" v-if="popupState.stripeCheckoutPopup && !showNewPopup">
				<StripeCheckoutPopup :yesUpgrade="showLoadingNotif" />
				<div class="pop_up_closer" v-on:click="changePopup('stripeCheckoutPopup', false)"></div>
			</div>
			
			<div class="popup_wrpr active popup_up_down" v-if="popupState.backgroundRemoverPopup && !showNewPopup">
				<BackgroundRemoverPopup :yesUpgrade="showLoadingNotif" />
				<div class="pop_up_closer" v-on:click="changePopup('backgroundRemoverPopup', false)"></div>
			</div>

			<div class="popup_wrpr active popup_up_down" v-if="popupState.upgradePopup && showNewPopup && !isMobileView">
				<UpgradePopupNew :yesUpgrade="showLoadingNotif" :allowOnlyAnnual="allowOnlyAnnual" />
				<div class="pop_up_closer" v-on:click="changePopup('upgradePopup', false)"></div>
			</div>

			<div class="popup_wrpr active popup_up_down" v-if="popupState.upgradePopup && isMobileView">
				<UpgradePopupMobile :yesUpgrade="showLoadingNotif" :allowOnlyAnnual="allowOnlyAnnual" />
				<div class="pop_up_closer" v-on:click="changePopup('upgradePopup', false)"></div>
			</div>


		</transition>

		<transition name="fade">
			<div class="popup_wrpr active" v-if="popupState.signIn">
				<SignIn />
				<div class="pop_up_closer" v-on:click="changePopup('signIn', false)"></div>
			</div>
		</transition>
		<transition name="fade">
			<div class="popup_wrpr active" v-if="popupState.signUp">
				<SignUp />
				<div class="pop_up_closer" v-on:click="changePopup('signUp', false)"></div>
			</div>
		</transition>
		<transition name="fade">
			<div class="popup_wrpr active" v-if="popupState.checkoutRegister">
				<checkoutRegister />
				<div class="pop_up_closer" v-on:click="changePopup('checkoutRegister', false)"></div>
			</div>
		</transition>

		<transition name="fade">
			<div class="popup_wrpr active" v-if="popupState.register">
				<Registration />
				<div class="pop_up_closer" v-on:click="changePopup('register', false)"></div>
			</div>
		</transition>

		<transition name="fade">
			<div class="popup_wrpr active" v-if="popupState.resetPopup">
				<ResetPopup :resetPopupText="resetPopupText" />
				<div class="pop_up_closer" v-on:click="changePopup('resetPopup', false)"></div>
			</div>
		</transition>
		<div class="popup_wrpr active" v-if="!confirmPopUpConfig.isHide">
			<ConfirmPopUp :confirmPopUpConfig="confirmPopUpConfig" />
		</div>

		<div class="popup_wrpr active" v-if="popupState.facesPopup">
			<FacesPopup :confirmPopUpConfig="confirmPopUpConfig" content="abc" />
		</div>
		

		<transition name="fade">
			<transition name="slide-fade">
				<div
						class="popup_wrpr active"
						v-bind:class="{ [notifPopupClass]: notifPopupClass }"
						v-if="loadingNotif"
				>
					<div class="plus_watermark notifiers box_style">
						<div class="wrap_title">
							<div class="icon_wrpr">
								<transition name="fade">
									<CircleLoading v-if="loadingPopupIconCircle" />
								</transition>
								<transition name="fade">
									<span class="inch-icon inch-saved-icon" v-if="loadingPopupIconAccept"></span>
								</transition>
							</div>
							<transition name="fade" mode="out-in">
								<div class="title" :key="loadingNotifText">{{loadingNotifText}}</div>
							</transition>
						</div>
					</div>
				</div>
			</transition>
		</transition>

		<transition name="fade">
			<div class="popup_wrpr active" v-if="saveNotif">
				<div class="plus_watermark notifiers box_style">
					<div class="wrap_title">
						<span class="inch-icon inch-saved-icon"></span>
						<div class="title">{{saveNotifText}}</div>
					</div>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<div class="popup_wrpr active without_bg delete_popup" v-if="deletePopupState">
				<div class="popup save_popup">
					<div class="popup_header">
						<h3>Lorem ipsum</h3>
						<a class="close_popup_btn" v-on:click="deletePopupToggle">
							<span class="inch-icon inch-close-icon"></span>
						</a>
					</div>
					<div class="popup_body">
						<div class="popup_body_row_item reset_popup_content">
							<p>Delete project?</p>
						</div>
						<div class="popup_body_row_item">
							<div class="btns-group mt0">
								<a class="grey_btn" v-on:click="deletePopupToggle">No</a>
								<a class="blue_btn" v-on:click="deletePopupToggle">Yes</a>
							</div>
						</div>
					</div>
				</div>
				<div class="pop_up_closer" v-on:click="deletePopupToggle"></div>
			</div>
		</transition>

		<Preloader :show="getPreloader.isShow" :message="getPreloader.message" />
	</footer>
</template>

<script>
	import ZoomBlock from "@/components/ZoomBlock.vue";
	import ProjectManager from "@/components/ProjectManager.vue";
	import SaveGoogleDrive from "@/components/SaveGoogleDrive.vue";
	import SaveAsProject from "@/components/SaveAsProject.vue";
	import SaveToComputer from "@/components/SaveToComputer.vue";
	import SuggestFeature from "@/components/SuggestFeature.vue";
	// import UpgradePopup from "@/components/UpgradePopup.vue";
	import UpgradePopupNew from "@/components/UpgradePopupNew.vue";
	import FacesPopup from "@/components/facesPopup.vue";	
	import UpgradePopupMobile from "@/components/UpgradePopupMobile.vue";
	import StripeCheckoutPopup from "@/components/StripeCheckoutPopup.vue";
	import SocialLoginPopup from "@/components/Popups/SocialLoginPopup.vue";
	import CongratsAiPopup from "@/components/Popups/CongratsAiPopup.vue";
	import TrialPopup from "@/components/Popups/TrialPopup.vue";
	import BackgroundRemoverPopup from "@/components/BackgroundRemoverPopup.vue";
	import SignIn from "@/components/SignIn.vue";
	import SignUp from "@/components/SignUp.vue";
	import checkoutRegister from "@/components/StripeCheckoutRegister.vue";
	import Registration from "@/components/Registration.vue";
	import ResetPopup from "@/components/ResetPopup.vue";
	import ConfirmPopUp from "@/components/confirmPopUp.vue";

	import PopupGraphics from "@/components/popupPanel/PopupGraphics.vue";
	import PopupUnsplash from "@/components/popupPanel/PopupUnsplash.vue";
	import CircleLoading from "@/assets/img/sending_feedback.svg?inline";

	import Preloader from "@/components/Preloader.vue";
	import { trackMxEvent } from '@/libs/mixpanel.js';

	export default {
		name: "Footer",
		components: {
			ZoomBlock,
			ProjectManager,
			SaveAsProject,
			SaveToComputer,
			SuggestFeature,
			// UpgradePopup,
			UpgradePopupNew,
			UpgradePopupMobile,
			StripeCheckoutPopup,
			CongratsAiPopup,
			SocialLoginPopup,
			TrialPopup,
			BackgroundRemoverPopup,
			ResetPopup,
			SaveGoogleDrive,
			CircleLoading,
			PopupGraphics,
			PopupUnsplash,
			Preloader,
			ConfirmPopUp,
			FacesPopup,
			SignIn,
			SignUp,
			checkoutRegister,
			Registration
		},
		props: {
			footerAppendClass: String,
		},
		data: function () {
			return {
				isMobileView: false,
				showNewPopup: false,
				confirmPopUpConfig: {},
				loadingNotif: false,
				loadingNotifText: "",
				saveNotif: false,
				saveNotifText: "",
				saveProjectImg: require("@/assets/img/sample_image.png"),
				resetPopupText:
						"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore?",
				loadingPopupIconCircle: true,
				loadingPopupIconAccept: false,
				deletePopupState: false,
				notifPopupClass: ""
			};
		},
		mounted(){
			window.gg = this;
			this.isMobileView = this.$store.state.isMobileView;
			if(window.show_new_popup){
				this.showNewPopup = true;
			}
		},
		computed: {
			popupState() {
				return this.$store.getters.POPUPS;
			},
			savedProjects() {
				return this.$store.getters.SAVEDPROJECTS;
			},
			layersButton() {
				return this.$store.state.global.layersPanel;
			},

			getPreloader() {
				return this.$store.state.preloader;
			},

			getConfirmPopUpConfig() {
				return this.$store.state.confirmPopUp.config;
			},

			allowOnlyAnnual() {
				return parseInt(this.$store.state.INFO?.allow_only_annual_plan) ?? 0;
			},
		},

		watch: {
			getConfirmPopUpConfig(config) {
				this.confirmPopUpConfig = config;
			}
		},

		created() {
			this.confirmPopUpConfig = this.$store.state.confirmPopUp.config;
		},

		methods: {
			openLayers() {
				this.$store.dispatch("layersToggle");
			},

			async showChangeByStep(step) {
				await this.$lib._cache.showChangeByStep(step);
				// await this.$lib._cache.updateHistoryChanges();
				this.$store.state.fitToScreenComp += 1;
				this.$store.dispatch("isUpdateCache");
			},

			changePopup(property, value) {
				try {
					if (property === 'trialPopup' && value === false) {
						trackMxEvent('TrialPopup closed outer')
					}
				} catch (error) {
					// 
				}
				
				this.$store.dispatch("popupsToggle", { property, value });
			},

			changeBackgroundImage(){
				const element = document.querySelector(".img-area")
				const style = getComputedStyle(element);
				let imageName = style.backgroundImage.split('/').pop().split('.')[0];

				(imageName == 'mask' ? imageName = 'mask2': imageName = 'mask');
				this.$store.dispatch("imageNameToggle", { imageName });
			},
			showLoadingNotif(
					text,
					savedPopupText,
					savedPopupStayTime,
					thisNotifPopupClass
			) {
				//_LIB.app.download(savedPopupText);

				savedPopupStayTime = savedPopupStayTime || 1500;
				this.loadingNotif = !this.loadingNotif;
				this.loadingNotifText = text;
				this.notifPopupClass = thisNotifPopupClass || "";

				setTimeout(() => {
					this.loadingNotif = !this.loadingNotif;
					this.loadingNotifText = "";

					if (savedPopupText) {
						this.loadingPopupIconCircle = !this.loadingPopupIconCircle;
						this.loadingPopupIconAccept = !this.loadingPopupIconAccept;

						this.loadingNotif = !this.loadingNotif;
						this.loadingNotifText = savedPopupText;

						setTimeout(() => {
							this.loadingNotif = !this.loadingNotif;
							this.saveNotifText = "";
							this.loadingPopupIconCircle = !this.loadingPopupIconCircle;
							this.loadingPopupIconAccept = !this.loadingPopupIconAccept;
						}, savedPopupStayTime);
					}
				}, 1500);
			},
			deletePopupToggle() {
				return (this.deletePopupState = !this.deletePopupState);
			},
		},
	};
</script>
<style scoped>

  footer.footer_mobile {
    width: 0;
    z-index: 120;
    background: none;
  }
  .footer_mobile .popup_wrpr {
    background: rgba(0, 0, 0, 0.8);
  }

</style>

s.loadingNotif = !this.loadingNotif;
this.saveNotifText = "";
this.loadingPopupIconCircle = !this.loadingPopupIconCircle;
this.loadingPopupIconAccept = !this.loadingPopupIconAccept;
}, savedPopupStayTime);
}
}, 1500);
},
deletePopupToggle() {
return (this.deletePopupState = !this.deletePopupState);
},
},
};
</script>
