<template>
    <div :class="[{'upgrade_mobile' : isMobileView}]" class="congratsai_popup_outer">
        <div class="form_loading" v-if="isLoading">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div>
            <div class="popup save_popup upgrade-popup subs_popup reg-var" v-if="isOnline" :style="{ height: '415px' }">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="0 0 36 30">
                    <g id="emblem" transform="translate(-586 -239)">
                        <path id="bg" d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
                            transform="translate(586 239)" fill="#f94fd3" />
                        <path id="plus"
                            d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
                            transform="translate(552.992 245)" fill="#fff" />
                    </g>
                </svg>
                <div :class="{ is_pwa_popup: is_pwa }">
                    <div class="content" style="margin-top: 19px; width: 348px">
                        <div class="">
                            <div class="">
                                <h2 class="title old_update_popup_1"
                                    :style="{ 'text-align': 'left', 'font-size': '26px'}">
                                    Congratulations!
                                </h2>
                                <p class="all-plus-future"
                                    :style="{ 'margin-left': '0px', 'margin-bottom': '32px' }">
                                    You've earned 25 free AI credits every month. Happy creating!
                                </p>
                            </div>
                        </div>
                        <div v-if="!is_pwa" :style="{ 'text-align': 'center', 'margin-top': '36px' }">
                            <div class="">
                                <form v-on:submit.prevent="skipSubmit" class="reg-form">
                                    
                                    <ul class="list-plus list-plus--ai ff">
                                        <li><b>25 free AI credits</b> – Use your free AI credits to explore and create every month!.</li>
                                        <li><b>Unlimited AI access </b> – Unlock unlimited AI access by upgrading to the Total AI plan anytime.
</li>
                                        <li><b>Let your creativity flow</b> – enjoy the power of AI at your fingertips!</li>
                                    </ul>

                                    <div style="margin-top:16px">
                                        <button type="submit" @click="onSubmit()" class="btn-orange" style="color: #fff; margin: 0; max-width: 100%; height: 48px; font-size:12px;box-shadow: 0px 2px 24px #FA640066;">
                                            CONTINUE
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <div>
                        <a class="close_popup_btn" v-on:click="onSubmit()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="x" transform="translate(-198 -160)">
                                    <circle id="Ellipse_106" data-name="Ellipse 106" cx="9" cy="9" r="9" transform="translate(198 160)"
                                        fill="#000" opacity="0.5" />
                                    <g id="x_icon" data-name="x icon" transform="translate(203 165)">
                                        <rect id="Rectangle_435" data-name="Rectangle 435" width="10.335" height="0.979" rx="0.489"
                                            transform="translate(7.308 8) rotate(-135)" fill="#fff" />
                                        <rect id="Rectangle_436" data-name="Rectangle 436" width="10.335" height="0.979" rx="0.489"
                                            transform="translate(0 7.308) rotate(-45)" fill="#fff" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                        
                        <div v-if="!isMobileView" id="img-area">
                            <div id="img-area2" style="opacity: 0"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
import Acc from '@/libs/acc.js';
import { trackMxEvent } from '@/libs/mixpanel.js';

export default {
    name: "CongratsAiPopup",
    props: { yesUpgrade: Function },
    mounted: function(){
      try {
        trackMxEvent('Congrats popup shown')
      } catch (error) {
        // 
      }
    },
    

    data: function () {
        return {
            acc: new Acc(),
            isSubmitting: false,
            is_pwa: false,
            isLoading: false,
        };
    },
    
    methods: {
        skipSubmit() {},
        async onSubmit(event = 'read') {
            this.closeAllPopups();
            this.isSubmitting = true;
            try {
              trackMxEvent(`congratsAiPopup ${event}`)
            } catch (error) {
              // 
            }
            
            try {
              await this.acc.freeAiAck();
            } catch(err) { console.error(err); }

            this.isSubmitting = false;
        },

        changePopup(property, value) {
          if (value === false) {
            try {
              trackMxEvent('congratsAiPopup closed')
            } catch (error) {
              // 
            }
          }
            this.$store.dispatch("popupsToggle", { property, value });
        },

        closeAllPopups() {
            this.$store.dispatch("popupsToggle", {
                property: "congratsAiPopup",
                value: false
            });
        },
    },
    computed: {
        
        isMobileView() {
            return this.$store.state.isMobileView;
        },
    }
}
</script>  
  <style>
    .congratsai_popup_outer .list-plus--ai.ff li {
      height: auto !important;  
    }

    .congratsai_popup_outer .double--column {
        margin-top: -8px;
        display: flex;
        justify-content: space-between;
        /* padding-left: 8px; */
    }

    .congratsai_popup_outer .double--column .list-plus.list-plus--ai {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .congratsai_popup_outer .double--column .list-plus.list-plus--ai li {
        margin-top: 8px;
        height: 18px;
        /* width: 173px; */
        /* margin-left: 0 !important;
        margin-right: 0 !important; */
        flex: 0 0 calc(50% - 24px);
        box-sizing: border-box;
    }
    .congratsai_popup_outer .double--column .list-plus.list-plus--ai li::before {
      width: 24px!important;
      margin-left: -24px!important;
    }
    .congratsai_popup_outer .list-plus.list-plus--ai li:before {
        content: url('../../assets/svg/plus-ai.svg');
    }

    .congratsai_popup_outer .plans{
      list-style: none;
      margin: 12px 0 0;
      padding: 0;
      overflow: auto;
      overflow-x: hidden;
    }
  
  .congratsai_popup_outer .plans li{
    color: #AAAAAA;
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: 45px;
      border-bottom: 1px solid #333;
  }
  .congratsai_popup_outer .plans li:last-child{
    border-bottom: 0px;
  }
  
  .congratsai_popup_outer .plans li input[type=radio]{
    position: absolute;
    visibility: hidden;
  }
  
  .congratsai_popup_outer .plans li label{
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.15em;
    padding: 8px 15px 15px 40px;
    margin: 5px auto 0;
    height: 35px;
    z-index: 9;
    cursor: pointer;
    text-align: left;
    -webkit-transition: all 0.25s linear;
  }
  
  .congratsai_popup_outer .plans li:hover label{
      color: #333;
  }
  
  .congratsai_popup_outer .plans li .checkk{
    display: block;
    position: absolute;
    border: 5px solid #AAAAAA;
    border-radius: 100%;
    height: 27px;
    width: 27px;
    top: 10px;
    left: 0px;
      z-index: 5;
      transition: border .25s linear;
      -webkit-transition: border .25s linear;
  }
  
  .congratsai_popup_outer .plans li:hover .checkk {
    border: 5px solid #333;
  }
  
  .congratsai_popup_outer .plans li .checkk::before {
    display: block;
    position: absolute;
      content: '';
    border-radius: 100%;
    height: 12px;
    width: 12px;
    top: 3px;
      left: 3px;
    margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
  }
  
  .congratsai_popup_outer .plans input[type=radio]:checked ~ .checkk {
    border: 5px solid #E05A00;
  }
  
  .congratsai_popup_outer .plans input[type=radio]:checked ~ .checkk::before{
    background: #E05A00;
  }
  
  .congratsai_popup_outer .plans input[type=radio]:checked ~ label{
    color: #E05A00;
  }
  
    .congratsai_popup_outer .text-green {
      color: green;
    }
    .congratsai_popup_outer .reg-form .form_loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #8080809c;
      z-index: 10000;
    }
  
    .congratsai_popup_outer #img-area {
      flex: 0 0 50%;
      max-width: 50%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/8c4e89dca54fd86bac523e0e356ec7b4.png");
      background-size: cover, auto;
      background-position: center;
      transition: opacity 1s ease-in-out;
      z-index: -1;
      border-radius: 0 6px 6px 0;
    }
    .congratsai_popup_outer #img-area2 {
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/8c4e89dca54fd86bac523e0e356ec7b4.png");
      background-size: cover, auto;
      background-position: center;
      transition: opacity 1s ease-in-out;
      z-index: -1;
      border-radius: 0 6px 6px 0;
    }
  
    .congratsai_popup_outer .already-member {
      text-align: left;
      font: normal normal 300 14px/20px Soleil;
      letter-spacing: -0.28px;
      color: #727272;
    }
    
    .congratsai_popup_outer .list-plus li {
      margin-left: 30px;
      margin-top: 12px;
      font-size: 15px!important;
      text-align: left;
      font: normal normal 300 16px/20px Soleil;
      letter-spacing: -0.32px;
      color: #727272;
      opacity: 1;
    }
    .congratsai_popup_outer .reg-form .btn-orange + .btn-orange {
      margin-top: 12px!important;
    }
    .congratsai_popup_outer .double--column .list-plus.list-plus--ai li {
      margin-left: 24px!important;
    }
    .congratsai_popup_outer .list-plus li:before {
      content: url("../../assets/svg/plus.svg"); /* FontAwesome Unicode */
      font-family: icomoon;
      color: #fa9d1c;
      display: inline-block;
      margin-left: -30px;
      /* margin-right: 10px; */
      width: 30px; /* same as padding-left set on li */
    }
    .congratsai_popup_outer .content {
      flex: 0 0 50%;
      max-width: 50%;
      position: absolute;
      /*left:0;*/
      /*top:0;*/
      width: 100%;
      height: 100%;
    }
  
    .congratsai_popup_outer .popup.save_popup.upgrade-popup {
      text-align: unset !important;
      padding: 0px 35px 0px 40px !important;
      max-width: 100%;
      width: 840px;
      height: 605px;
      overflow: visible;
    }
    .congratsai_popup_outer .popup.save_popup.upgrade-popup.reg-var{
        padding: 0px 35px 0px 36px !important;
    }
    .congratsai_popup_outer .form_errors {
      text-align: left;
      font: normal normal normal 14px/34px Soleil;
      color: #ff5353;
    }
    .congratsai_popup_outer .plus-benefits{
        position: absolute;
        bottom: 1.4rem;
        right: 0;
        background: #fff;
        width: 47%;
        padding: 20px;
    }
    .congratsai_popup_outer .reg-var .list-plus li{
      margin-top: 8px;
      height: 18px;
    }
    .congratsai_popup_outer .reg-var .list-plus li:before{
      vertical-align: middle;
      margin-top: -2px;
    }
    .congratsai_popup_outer .agree-links{
      text-align: left;
      font: normal normal normal 12px/16px Soleil;
      letter-spacing: 0px;
      color: #828C9C;
      margin-top: 25px;
    }
    .congratsai_popup_outer .agree-links a{
      text-decoration: underline;
    }
    .congratsai_popup_outer .reg-form input{
      box-shadow: 0px 1px 2px #00000014;
      border: 1px solid #F2F2F2;
      border-radius: 6px;
      opacity: 1;
      width: 100%;
    }
  
    .congratsai_popup_outer .form_loading {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .congratsai_popup_outer .lds-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
  
    .congratsai_popup_outer .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
    .congratsai_popup_outer .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .congratsai_popup_outer .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .congratsai_popup_outer .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  
    .congratsai_popup_outer .all-plus-future {
      text-align: left !important;
      font: normal normal 300 16px/20px Soleil !important;
      letter-spacing: -0.32px !important;
      color: #727272 !important;
      opacity: 1;
    }
  
    .congratsai_popup_outer .popup.save_popup.upgrade-popup {
      padding: 70px 100px 60px 100px;
    }
  
    .congratsai_popup_outer .decoration {
      text-decoration: line-through;
    }
  
   .congratsai_popup_outer  .upgrade-popup {
      transition: 0.2s;
    }
    .congratsai_popup_outer .upgrade_mobile{
      width: 100%;
      max-width: 400px;
    }
   .congratsai_popup_outer  .upgrade_mobile .content {
      max-width: 100%!important;
      position: relative;
      margin: auto;
    }
   .congratsai_popup_outer  .upgrade_mobile .popup.save_popup.upgrade-popup.subs_popup {
      width: auto;
      border-radius:6px;
    }
  .congratsai_popup_outer   .upgrade_mobile .plus-benefits{
      position: relative;
      bottom: -6px;
      right: 0;
      background: #fff;
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      display: none;
    }
  .congratsai_popup_outer   .upgrade_mobile .plus-benefits p{
      text-align: center;
      text-transform: uppercase;
    }
  </style>
  
