<template>
    <div :class="[{'upgrade_mobile' : isMobileView}]" class="sociallogin_popup_outer">
        <div class="form_loading" v-if="isLoading">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div>
            <div class="popup save_popup upgrade-popup subs_popup reg-var" v-if="isOnline" :style="{ height: '415px' }">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="0 0 36 30">
                    <g id="emblem" transform="translate(-586 -239)">
                        <path id="bg" d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
                            transform="translate(586 239)" fill="#ff9360" />
                        <path id="plus"
                            d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
                            transform="translate(552.992 245)" fill="#fff" />
                    </g>
                </svg>
                <div :class="{ is_pwa_popup: is_pwa }">
                    <div class="content" style="margin-top: 19px; width: 418px">
                        <div class="">
                            <div class="">
                                <h2 class="title old_update_popup_1"
                                    :style="{ 'text-align': 'left', 'font-size': '26px'}">
                                    Unlock Free AI Access - Instantly!
                                </h2>
                                <p class="all-plus-feature"
                                    :style="{ 'margin-left': '0px', 'margin-bottom': '25px' }">
                                    Get 3 FREE AI-Generated Images every month - No Cost, No Hassle! We use it only to validate your access - no spam, no hidden fees! Plus, it helps us keep AI features fair for all users.
                                </p>
                            </div>
                        </div>
                        <div v-if="!is_pwa" :style="{ 'text-align': 'center' }">
                            <div class="">
                                <form v-on:submit.prevent="skipSubmit" class="reg-form">
                                    
                                    <ul class="list-plus list-plus--ai ff">
                                        <li>3 AI Images every month</li>
                                        <li>Exclusive Early Access to AI Features</li>
                                        <li>Seamless Experience - No Long Sign-Ups!</li>
                                    </ul>

                                    <div style="margin-top:50px">
                                      <button type="submit" @click="socialAuthenticate('google')" class="gsi-material-button">
                                        <div class="gsi-material-button-state"></div>
                                        <div class="gsi-material-button-content-wrapper">
                                          <div class="gsi-material-button-icon">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                                              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                              <path fill="none" d="M0 0h48v48H0z"></path>
                                            </svg>
                                          </div>
                                          <span class="gsi-material-button-contents">Continue with Google</span>
                                        </div>
                                      </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <div>
                        <a class="close_popup_btn" v-on:click="changePopup('socialLoginPopup', false)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="x" transform="translate(-198 -160)">
                                    <circle id="Ellipse_106" data-name="Ellipse 106" cx="9" cy="9" r="9" transform="translate(198 160)"
                                        fill="#000" opacity="0.5" />
                                    <g id="x_icon" data-name="x icon" transform="translate(203 165)">
                                        <rect id="Rectangle_435" data-name="Rectangle 435" width="10.335" height="0.979" rx="0.489"
                                            transform="translate(7.308 8) rotate(-135)" fill="#fff" />
                                        <rect id="Rectangle_436" data-name="Rectangle 436" width="10.335" height="0.979" rx="0.489"
                                            transform="translate(0 7.308) rotate(-45)" fill="#fff" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                        
                        <div v-if="!isMobileView" id="img-area">
                            <div id="img-area2" style="opacity: 0"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
import Acc from '@/libs/acc.js';
import { trackMxEvent } from '@/libs/mixpanel.js';
import { Providers } from 'universal-social-auth'

export default {
    name: "SocialLoginPopup",
    mounted: function(){
      try {
        trackMxEvent('SocialLogin popup shown')
      } catch (error) {
        // 
      }
    },
    
    data: function () {
        return {
            acc: new Acc(),
            is_pwa: false,
            isLoading: false,
        };
    },
    
    methods: {
        socialAuthenticate (provider) {
          const ProData = Providers[provider]
          this.$Oauth.authenticate(provider, ProData).then(async (response) => {
            const rsp = response
            if (rsp.code) {
              try {
              const pdata = { code: rsp.code }
              const result = await this.acc.socialLogin(provider, pdata);
              if (result) {
                this.$store.dispatch('refreshUser');
                try {
                  trackMxEvent('SocialLogin authenticated')
                } catch (error) {
                  // 
                }
                this.changePopup('socialLoginPopup', false)
              }
              } catch (error) {
              //
              }
            }
          }).catch((err) => {
            console.log(err)
          })
        },

        changePopup(property, value) {
          if (value === false) {
            try {
              trackMxEvent('socialLoginPopup closed')
            } catch (error) {
              // 
            }
          }
            this.$store.dispatch("popupsToggle", { property, value });
        },

        closeAllPopups() {
            this.$store.dispatch("popupsToggle", {
                property: "socialLoginPopup",
                value: false
            });
        },
    },
    computed: {
        
        isMobileView() {
            return this.$store.state.isMobileView;
        },
    }
}
</script>  
  <style>
    .gsi-material-button {
      background-color: #f2f2f2;
      background-image: none;
      border: none;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #1f1f1f;
      cursor: pointer;
      font-size: 16px;
      height: 40px;
      letter-spacing: 0.25px;
      outline: none;
      overflow: hidden;
      padding: 0 12px;
      position: relative;
      text-align: center;
      -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
      transition: background-color .218s, border-color .218s, box-shadow .218s;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;
      max-width: 400px;
      min-width: min-content;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    }

    .gsi-material-button .gsi-material-button-icon {
      height: 20px;
      margin-right: 12px;
      min-width: 20px;
      width: 20px;
    }

    .gsi-material-button .gsi-material-button-content-wrapper {
      -webkit-align-items: center;
      align-items: center;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      height: 100%;
      justify-content: space-between;
      position: relative;
      width: 100%;
    }

    .gsi-material-button .gsi-material-button-contents {
      -webkit-flex-grow: 1;
      flex-grow: 1;
      font-family: 'Roboto', arial, sans-serif;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
    }

    .gsi-material-button .gsi-material-button-state {
      -webkit-transition: opacity .218s;
      transition: opacity .218s;
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
    .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
      background-color: #001d35;
      opacity: 12%;
    }

    .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }

    .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
      background-color: #001d35;
      opacity: 8%;
    }

    .sociallogin_popup_outer .list-plus--ai.ff li {
      height: auto !important;  
    }

    .sociallogin_popup_outer .list-plus.list-plus--ai li:before {
        content: url('../../assets/svg/plus-ai.svg');
    }
   
    .sociallogin_popup_outer .reg-form .form_loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #8080809c;
      z-index: 10000;
    }
  
    .sociallogin_popup_outer #img-area {
      flex: 0 0 50%;
      max-width: 50%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/8c4e89dca54fd86bac523e0e356ec7b4.png");
      background-size: cover, auto;
      background-position: center;
      transition: opacity 1s ease-in-out;
      z-index: -1;
      border-radius: 0 6px 6px 0;
    }
    .sociallogin_popup_outer #img-area2 {
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/8c4e89dca54fd86bac523e0e356ec7b4.png");
      background-size: cover, auto;
      background-position: center;
      transition: opacity 1s ease-in-out;
      z-index: -1;
      border-radius: 0 6px 6px 0;
    }
  
    .sociallogin_popup_outer .list-plus li {
      margin-left: 30px;
      margin-top: 12px;
      font-size: 15px!important;
      text-align: left;
      font: normal normal 300 16px/20px Soleil;
      letter-spacing: -0.32px;
      color: #727272;
      opacity: 1;
    }
    .sociallogin_popup_outer .list-plus li:before {
      content: url("../../assets/svg/plus.svg"); /* FontAwesome Unicode */
      font-family: icomoon;
      color: #fa9d1c;
      display: inline-block;
      margin-left: -30px;
      /* margin-right: 10px; */
      width: 30px; /* same as padding-left set on li */
    }
    .sociallogin_popup_outer .content {
      flex: 0 0 50%;
      max-width: 50%;
      position: absolute;
      /*left:0;*/
      /*top:0;*/
      width: 100%;
      height: 100%;
    }
  
    .sociallogin_popup_outer .popup.save_popup.upgrade-popup {
      text-align: unset !important;
      padding: 0px 35px 0px 40px !important;
      max-width: 100%;
      width: 980px;
      height: 605px;
      overflow: visible;
    }
    .sociallogin_popup_outer .popup.save_popup.upgrade-popup.reg-var{
        padding: 0px 35px 0px 36px !important;
    }
    .sociallogin_popup_outer .reg-var .list-plus li{
      margin-top: 8px;
      height: 18px;
    }
    .sociallogin_popup_outer .reg-var .list-plus li:before{
      vertical-align: middle;
      margin-top: -2px;
    }
    .sociallogin_popup_outer .form_loading {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .sociallogin_popup_outer .lds-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
  
    .sociallogin_popup_outer .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
    .sociallogin_popup_outer .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .sociallogin_popup_outer .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .sociallogin_popup_outer .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  
    .sociallogin_popup_outer .all-plus-feature {
      text-align: left !important;
      font: normal normal 300 16px/20px Soleil !important;
      letter-spacing: -0.32px !important;
      color: #727272 !important;
      opacity: 1;
    }
  
    .sociallogin_popup_outer .popup.save_popup.upgrade-popup {
      padding: 70px 100px 60px 100px;
    }
  
   .sociallogin_popup_outer  .upgrade-popup {
      transition: 0.2s;
    }
    .sociallogin_popup_outer .upgrade_mobile{
      width: 100%;
      max-width: 400px;
    }
   .sociallogin_popup_outer  .upgrade_mobile .content {
      max-width: 100%!important;
      position: relative;
      margin: auto;
    }
   .sociallogin_popup_outer  .upgrade_mobile .popup.save_popup.upgrade-popup.subs_popup {
      width: auto;
      border-radius:6px;
    }
  </style>
  
