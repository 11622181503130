import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

var userFiles = "";
export const store = new Vuex.Store({
    state: {
        allowAiTools: false,
        isArtificialIntelligencePlan: false,
        listAiItems: [
            'Face Restoration',
            'Background Removal',
            'Image De-oldify',
            'Prompt Generation',
            'Inpainting & Removal',
            'Image Upscale',
            'Face to Sticker',
            'Photo to Anime'
        ],

        isInitiateApp: false,
        isMobileView: false,
        storeOnMobile: false,
        drawEvent: false,
        drawEventVisible: false,
        earseConfig: false,
        projectSave: false,
        autosave: {
            loading: false,
            isHaveChanges: false,
            project: false,
            lastSaveId: false,
            qq: 1
        },
        fitToScreenComp: 1,
        fromAnotherComputer: false,
        lockZoom: false,
        lockZoomScroll: false,
        _applySelectedEffect: 0,
        _callbackSave: 0,
        selectedEditPanel: '',
        unsplash: {
            collections: []
        },

        dropdownMenuOption: false,
        selectGlobalDropdown: false,
        userUploadFiles: [],

        paymentToken: false,
        saveConfigPresets: {
            jpeg: {quality: 80},
            pdf: {quality: 70},
            png: {quality: 100}
        },

        saveConfig: {
            filename: '',
            type: 0
        },
        showShortcuts: false,
        currentZoomPosition: {x: 0, y: 0, zoom: 1},
        contextEvent: null,
        graphicsPopUp: null,
        selectedLayer: false,
        keyDownList: [],
        pixiColorPicker: {},
        effectServerURL: null,
        domain: window.locationOrigin,
        prefixURL: window.prefixURL, //'/editor_ajax',
        routerPrefixURL: window.routerPrefixURL, //'/editor_ajax',
        plans: [],
        tipsComponents: {},
        triggerDropdown: 0,
        isPremiumAccount: false,
        USER: {},
        ADINFO: {},
        INFO: {},
        fonts: [],
        updateMyGraphics: 1,
        currentFont: null,
        incrBodyDownFabric: 0,
        isOnline: navigator.onLine,
        textEvent: {},
        textPanelUpdate: 0,
        favorites: {
            filters: [],
            overlays: [],
            masks: [],
            frames: [],
            effects: []
        },
        resetCanvas: 0,
        confirmPopUp: {
            answer: {
                name: '',
                result: false
            },

            config: {
                isHide: true,
                popupTitle: '',
                popupBody: '',
                btnYes: '',
                btnNo: '',
                name: ''
            }
        },
        globalTextConfig: {
            params: {},
            default: {
                shadowParams: {color: "#000000", blur: 50, offsetY: 0, offsetX: 0},
                backgroundTextParams: {},
                textOutlineParams: {},
                layerPopConfig: {
                    intensity: 0
                },
                fontSize: 34
            },

            fonts: [
                "Open Sans",
                "Roboto",
                "Lato",
                "Arial",
                "Proxima Nova",
                "Lemonada",
                "Sriracha",
                "Odibee Sans",
                "Montserrat",
                "Raleway"
            ]
        },

        globalLayerPopUp: {
            params: {},

            default: {
                blendMode: "Normal",
                opacity: 100,
                color: undefined,
                intensity: 30
            },

            blendModes: [
                /* "Overlay",
                "Add",
                "Diff",
                "Subtract", */
                "Normal",
                "_divider",
                "Darken",
                "Multiply",
                "Color Burn",
                "_divider",
                "Lighten",
                "Screen",
                "Color Dodge",
                "_divider",
                "Overlay",
                "Soft Light",
                "Hard Light",
                "_divider",
                "Difference",
                "Exclusion",
                "_divider",

                // "Tint",
            ]
        },
        popupGraphicsData: [],
        isInitialLib: false,
        isShowLayerProperties: false,
        isUpdatedCache: 0,
        preloader: {
            message: '',
            isShow: false
        },
        selectedColor: {
            "color": {
                "rgba": {"r": 105, "g": 105, "b": 105, "a": 1},
                "hsv": {"h": 0, "s": 0, "v": 0.41},
                "hex": "#696969"
            }
        },
        userFile: false,
        userFiles: [],
        userProjects: [],
        ranges: {
            editRotateStraighten: {
                value: 0,
            },
            editExplosureBrightness: {
                value: 0,
            },
            editExplosureContrast: {
                value: 0,
            },
            editExplosureHighlights: {
                value: 0,
            },
            editExplosureShadows: {
                value: 0,
            },
            editColorHue: {
                value: 0,
            },
            editColorSaturation: {
                value: 0,
            },
            editColorTemperature: {
                value: 0,
            },
            editColorTint: {
                value: 0
            },
            editVibranceVibrance: {
                value: 30,
            },
            editDetailsSharpen: {
                value: 20,
            },
            editDetailsClarity: {
                value: 0,
            },
            editDetailsBlur: {
                value: 0,
            },
            editVignetteOpacity: {
                value: 50,
            },
            editRoundRadius: {
                value: 20,
            },
            saveImageJpgQuality: {
                value: 80,
            },
            saveImagePngQuality: {
                value: 100,
            },
            saveImagePdfQuality: {
                value: 50,
            },
            imagePropertiesOpacity: {
                value: 50
            },
            layerPropPopupOpacity: {
                value: 50
            },
        },

        settings: {
            showGuides: false,
            snapToGuides: true,
            autoloadLastProject: true,
            enableAutosave: false
        },

        sidebarPanel: {
            panelName: '',
            panelClass: '',
            panelSettings: ''
        },
        global: {
            footerClass: '',
            zoom: 100,
            zoomDashboard: 100,
            zoomDashboardCounter: 0,
            layersPanel: false,
            popupStatus: false,
            showPropertiesGraphics: false,
            isTextPanelDisabled: true,
            popupLayerPropVisible: false
        },

        popups: {
            projectManager: false,
            SaveAsProject: false,
            saveToComputer: false,
            afterSaveAd: false,
            suggestFeature: false,
            upgradePopup: false,
            facesPopup: false,
            stripeCheckoutPopup: false,
            trialPopup: false,
            congratsAiPopup: false,
            socialLoginPopup: false,
            resetPopup: false,
            backgroundRemoverPopup: false,
            deletePopup: false,
            changesApplied: false,
            popupGraphics: false,
            popupUnsplash: false,
            signIn: false,
            signUp: false,
            checkoutRegister:false,
            register: false,
            googleDrive: false,
        },

        savedProjects: [{
            id: 1,
            title: "Wedding Invitation",
            img: require("@/assets/img/img-1.png"),
        },
            {
                id: 2,
                title: "Corporate Header",
                img: require("@/assets/img/img-1.png"),
            },
            {
                id: 3,
                title: "John Doe",
                img: require("@/assets/img/img-1.png"),
            },
            {
                id: 4,
                title: "Wedding Invitation for John & Cate",
                img: require("@/assets/img/img-1.png"),
            },
            {
                id: 5,
                title: "John & Cate",
                img: require("@/assets/img/img-1.png"),
            },
            {
                id: 6,
                title: "Art Picture",
                img: require("@/assets/img/img-1.png"),
            },
            {
                id: 7,
                title: "Abstraction",
                img: require("@/assets/img/img-1.png"),
            },
        ],
        textElementState: false,
        premiumStatus: false
    },

    getters: {
        SETTINGS: state => {
            return state.settings
        },
        GLOBAL: state => {
            return state.global
        },
        POPUPS: state => {
            return state.popups
        },
        SAVEDPROJECTS: state => {
            return state.savedProjects
        },
        TEXTELEMENTSTATES: state => {
            return state.textElementState
        },
    },

    mutations: {
        isMobileView(state, status) {
            state.isMobileView = status;
        },
        [userFiles](state, files) {
            _files = files;
        },

        isInitialLib(state, status) {
            state.isInitialLib = status;
        },

        globalLayerPopUpParams(state, obj) {
            state.globalLayerPopUp.params = obj;
        },

        userProjects(state, files) {
            state.userProjects = files;
        },

        resetCanvas(state) {
            state.resetCanvas += 1;
        },

        popupGraphicsData(state, data) {
            state.popupGraphicsData = data;
        },

        showLayerProperties(state, isShow) {
            state.global.layersPanel = isShow;
            state.isShowLayerProperties = isShow;
        },

        fabricSlide(state, isHide) {
            if (state && !state.isMobileView) { // Что бы не валило warn
                if (document.getElementsByClassName('fabricSlide').length) {
                    document.getElementsByClassName('fabricSlide')[0]
                        .style = `display: ${isHide ? 'none' : 'flex'} !important`;
                }
            }
        },

        isUpdateCache(state) {
            state.isUpdatedCache += 1;
        },

        preloader(state, obj) {
            state.preloader = obj;
        },

        confirmPopUpConfig(state, config) {
            state.confirmPopUp.config = config;
        },

        textPanelUpdate(state) {
            state.textPanelUpdate += 1;
        },

        confirmPopUpAnswer(state, answer) {
            state.confirmPopUp.answer = answer;
        },

        // key, value
        favorites(state, data = {}) {
            state.favorites[data.key] = data.value;
        },

        USER(state, USER) {
            state.USER = USER;
        },

        async ADINFO(state, ADINFO) {
            state.ADINFO = ADINFO;
        },

        async INFO(state, INFO) {
            state.INFO = INFO;
        },

        userFile(state, file) {
            state.userFile = file;
        },

        selectedColor(state, color) {
            state.selectedColor = color;
        },

        zoomDashboard(state, zoom) {
            state.global.zoomDashboardCounter += 1;
            state.global.zoomDashboard = zoom;
        },

        popupsToggleStatus(state, value) {
            state.global.popupStatus = value;
        },

        sliderRangeStateChange(state, amount) {
            state.sliderRangeState = amount
        },
        addSliderRangeState(state, newState) {
            state.sliderRangeState = {...state.sliderRangeState, [newState[0]]: newState[1]}
        },
        settingsToggleState(state, propertyObj) {
            state.settings[propertyObj.property] = propertyObj.value;
        },
        sidebarPanelState(state, panel) {
            panel.panelName ? state.sidebarPanel.panelName = panel.panelName : false;
            panel.panelClass ? state.sidebarPanel.panelClass = panel.panelClass : false;
            panel.panelSettings ? state.sidebarPanel.panelSettings = panel.panelSettings : false;
        },
        footerState(state, value) {
            state.global.footerClass = value
        },
        zoomState(state, value) {
            state.global.zoom = value

        },
        layersState(state) {
            state.global.layersPanel = !state.global.layersPanel
        },

        popupsToggleState(state, propertyObj) {
            state.popups[propertyObj.property] = propertyObj.value;
        },


        currentFont(state, value) {
            state.currentFont = value;
        },
        textElementState(state, value) {
            state.textElementState = value
        },
        changePremiumStatus(state, value) {
            state.premiumStatus = value
        }
    },

    actions: {
        isMobileView({commit}, status) {
            commit('isMobileView', status);
        },
        async fontsList(store, fonts) {
            let response = await fetch(`${store.state.prefixURL}/api/fonts`, {
                method: "GET",
                mode: 'no-cors',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }).then(res => res.json());
            const isLoadedFonts = !!store.state.fonts.length;
            if (window.location !== window.parent.location) {
                response = response.map(item => ({
                    ...item,
                    url: item.url.replace('edit.cartoonize.net', 'cdn.cartoonize.net'),
                }))
            }

            store.state.fonts = response;
            try {
                if (!isLoadedFonts) {
                    const title = store.state.fonts[0].title.replace(/[0-9]/g, "").trim();
                    store.state.currentFont = title;
                }
            } catch (err) {
            }

            return response;
        },

        async abconverted({state}, data = {}) {
            const result = await fetch(`${state.prefixURL}/ab/converted`, {
                body: JSON.stringify({
                    user_id: document.head.querySelector("[name=uniq_id][content]")
                        .content,
                    email: data.email
                }),
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": document.head.querySelector(
                        "[name=csrf-token][content]"
                    ).content,
                    "Content-Type": "application/json"
                }
            }).then(response => response.json());

            return result;
        },

        async abclicked({state}, data = {}) {
            const result = await fetch(`${state.prefixURL}/ab/clicked`, {
                body: JSON.stringify({
                    user_id: document.head.querySelector("[name=uniq_id][content]")
                        .content
                }),
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": document.head.querySelector(
                        "[name=csrf-token][content]"
                    ).content,
                    "Content-Type": "application/json"
                }
            }).then(response => response.json());

            return result;
        },

        async deleteUserImage({state}, data = {}) {
            const result = await fetch(`${state.prefixURL}/files/delete`, {
                body: JSON.stringify({
                    user_id: document.head.querySelector("[name=uniq_id][content]")
                        .content,
                    id: data.id
                }),
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": document.head.querySelector(
                        "[name=csrf-token][content]"
                    ).content,
                    "Content-Type": "application/json"
                }
            }).then(response => response.json());

            state.userUploadFiles = state.userUploadFiles.filter(file => file.id != data.id);
            return result;
        },

        async getUserLibrary({state}, data = {}) {
            let user_id = document.head.querySelector("[name=uniq_id][content]")
                .content;

            return await fetch(
                `${state.prefixURL}/files/get?user_id=` +
                user_id +
                "&type=1",
                {
                    method: "GET",
                    headers: {
                        "X-CSRF-TOKEN": document.head.querySelector(
                            "[name=csrf-token][content]"
                        ).content,
                        "Content-Type": "application/json",
                    },
                }).then((response) => response.json());
        },

        async uploadUserPhoto({state, dispatch}, dataUpload = {}) {

            if (navigator.onLine) {
                var data = new FormData();

                for (const key in dataUpload) {
                    data.append(key, dataUpload[key]);
                }

                data.append(
                    "user_id",
                    document.head.querySelector("[name=uniq_id][content]").content
                );

                return await fetch(`${state.prefixURL}/files/upload`, {
                    method: "POST",
                    headers: {
                        "X-CSRF-TOKEN": document
                            .querySelector('meta[name="csrf-token"]')
                            .getAttribute("content"),
                    },
                    body: data,
                }).then((response) => response.json())
            } else {

                async function getBase64(file) {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    });
                };

                return {
                    success: true,
                    image: {
                        id: null,
                        path: await getBase64(dataUpload.file),
                        type: 1,
                    }
                }
            }
        },

        async getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },

        async favoritesPropChange({state, dispatch}, data = {}) {
            console.log(data);
            // panelOpened, panels, favoritesList, panelName, itemId, panelId
            const dataPanel = data.panels.reduce((d, panel, index) => {
                if (panel.panelItems) {
                    const itemIndex = panel.panelItems.findIndex(pi => pi.id == data.itemId);
                    if (itemIndex !== -1) {
                        d = {panelIndex: index, itemIndex};
                    }
                }

                return d;
            }, {});

            const favoriteIndex = data.favoritesList.findIndex(f => f.id == data.itemId);
            if (!(((!dataPanel.panelIndex && dataPanel.panelIndex != 0) ||
                (!dataPanel.itemIndex && dataPanel.itemIndex != 0)) &&
                data.panelOpened != "Favorites")) {
                const preset =
                    data.panelOpened == "Favorites"
                        ? data.favoritesList.find(f => f.id == data.itemId)
                        : data.panels[dataPanel.panelIndex].panelItems[dataPanel.itemIndex];
                if (preset) {
                    let isChecked = false;
                    if (favoriteIndex == -1) {
                        isChecked = true;
                        data.favoritesList.push(JSON.parse(JSON.stringify({
                            ...preset,
                            favoritesIconIsChecked: true,
                            panelId: data.panelId
                        })));

                        await dispatch("addToFavoritesAPI", {
                            name: data.panelName,
                            itemId: data.itemId
                        });
                    } else {
                        data.favoritesList.splice(favoriteIndex, 1);
                        await dispatch("deleteFromFavoritesAPI", {
                            name: data.panelName,
                            itemId: data.itemId
                        });
                    }

                    if (data.panelId && data.panelId != 0) {
                        data.panels = data.panels.map(panel => {
                            if (panel.id === data.panelId) {
                                panel.panelItems = panel.panelItems.map(panelItem => {
                                    if (panelItem.id === data.itemId) {
                                        panelItem.favoritesIconIsChecked = isChecked;
                                    }

                                    return panelItem;
                                });
                            }

                            return panel;
                        });
                    }
                }
            }

            state.favorites[data.panelName] = data.favoritesList || [];

            return {
                favoritesList: data.favoritesList || [],
                panels: data.panels || []
            };
        },

        openHideTips({state, dispatch}, componentName) {
            // if(!state.settings.showGuides) {
            //     return dispatch('popupsToggleStatus', { property: 'popupsToggleStatus', value: false });
            // }
            const tipsComponents = state.tipsComponents;
            let value = false;
            if (!tipsComponents[componentName]) {
                if (!tipsComponents._isHideAll) {
                    state.tipsComponents[componentName] = true;
                }

                // Костыль записи в локал стор

                //const data = JSON.from(localStorage.getItem('') || {});
                localStorage.setItem('tipsComponents', JSON.stringify(state.tipsComponents));
                //
                value = true;
            }

            if (tipsComponents._isHideAll) {
                value = false;
            }

            dispatch('popupsToggleStatus', {property: 'popupsToggleStatus', value});
        },

        async addToFavoritesAPI({state}, data = {}) {
            if (state.USER && typeof state.USER.id == 'number') {
                console.log(state.USER);
                return await fetch(`${state.prefixURL}/api/${data.name}/favorite/${data.itemId}`, {
                    method: "POST"
                }).then(res => res.json()).catch(() => false);
            }

        },

        async deleteFromFavoritesAPI({state}, data = {}) {
            if (state.USER && typeof state.USER.id == 'number') {
                return await fetch(`${state.prefixURL}/api/${data.name}/favorite/${data.itemId}/delete`, {
                    method: "POST"
                }).then(res => res.json()).catch(() => false);
            }
        },

        isPremiumCurrentUser() {
            const user = this.state.USER;

            const isPremium = (user && (user.role == 'superadmin' || user.status == 'vip' || user.isPremium));
            return !!isPremium;
        },


        _allowAiTools({state}) {
            const user = this.state.USER;
            const x = (user && (user.role == 'superadmin' || user.status == 'vip' || user.aiTools || ((user.status == 'premium' || user.status == 'free') && user.aiUsageConfig > 0 && user.aiUsage < user.aiUsageConfig)));
            
            state.allowAiTools = !!x;
            return !!x;
        },


        async refreshUser({dispatch, state}) {
            let user = {};


            function inIframe() {
                try {
                    return window.self !== window.top;
                } catch (e) {
                    return true;
                }
            }


            let in_iframe = inIframe() ? 1 : 0;
            var append_if_iframe = false;
            console.log("window is ", window.integration_key);
            if (in_iframe) {
                append_if_iframe = "?in_iframe=" + (in_iframe ? window.integration_key : 0);
            }

            try {
                const response = await fetch(state.prefixURL + '/user' + (append_if_iframe || ''),
                    {
                        cache: "no-cache"
                    }).then(res => res.json());
                // const response = await fetch(state.prefixURL + '/user' + (append_if_iframe || '')).then(res => res.json());
                user = response?.user ?? {};
            } catch (err) {
            }


            // try {
            //     const us = JSON.parse(localStorage.getItem('user_dev'));
            //     if(us) user = us;
            // } catch(err) {}
            await dispatch('USER', typeof user.id == 'number' ? user : null)


            state.isPremiumAccount = await dispatch('isPremiumCurrentUser');

            // state.allowAiTools = await dispatch('_allowAiTools');
            await dispatch('_allowAiTools');
            
            await dispatch('ADINFO')
            await dispatch('INFO')

            return;

        },

        async getUserFiles({state, dispatch}) {
            var tokenEl = document.querySelector('meta[name="csrf-token"]');
            var token = tokenEl.getAttribute('content');

            let {data} = await fetch(`${state.prefixURL}/files/get`, {
                body: JSON.stringify({user_id: document.head.querySelector("[name=uniq_id][content]").content}),
                method: "POST",
                headers: {'X-CSRF-TOKEN': token, "Content-Type": "application/json",}
            });
            console.log({data});
            dispatch("setUserFiles", data);
            return data;
        },

        async getUserProjects(context, value) {
            if (value) { //fsdf

            }
            var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            const USER = context.state.USER;

            if (!USER || (!USER.id && USER.id != 0)) {
                context.commit('userProjects', []);
            }
            let user_id = USER.id; /* : document.head.querySelector("[name=uniq_id][content]").content */
            ;

            if (!user_id) return context.commit('userProjects', []);
            var response = await fetch(`${context.state.prefixURL}/projects/get?user_id=` + user_id, {
                method: "GET",
                headers: {'X-CSRF-TOKEN': token, "Content-Type": "application/json",}
            }).then((response) => {


                return response.json();
            });
            context.commit('userProjects', response.projects);
        },

        async saveProject(context, data) {
            const USER = context.state.USER;
            console.time('Uploading project');
            
            if (!USER || (!USER.id && USER.id != 0)) {
                context.commit('userProjects', []);
            }

            //data = { ...data, ...(context.state.projectSave ? { project_id: context.state.projectSave.project_id } : {}) };

            var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            function dataURItoBlob(dataURI) {
                var byteString = atob(dataURI);
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                return new Blob([ab], { type: 'application/octet-stream' });
            }

            var fd = new FormData();
            for (var key in data) {
                if (key === 'base64' || key === 'thumb') {
                    let  base64WithoutPrefix = data[key].split(',');
                    if (base64WithoutPrefix[1]) {
                        base64WithoutPrefix = base64WithoutPrefix[1]
                    }
                    else{
                        base64WithoutPrefix = data[key]
                    }
                    const blob = dataURItoBlob(base64WithoutPrefix);
                    fd.append(key, blob, key === 'base64' ? 'base64_file.txt' : 'thumb_file.png');
                }
                else{
                    fd.append(key, data[key]);
                }
            }

            console.log('SAVE DATA: ', data);
            fd.append("user_id", USER.id/* document.head.querySelector("[name=uniq_id][content]").content */);

            var response = await fetch(`${context.state.prefixURL}/projects/upload`, {
                body: fd,
                method: "POST",
                headers: {'X-CSRF-TOKEN': token} //, "Content-Type": "application/json", }
            }).then((response) => {
                console.timeEnd('Uploading project');
                return response.json();
            })
            context.commit('userProjects', response.projects);

            context.state.projectSave = {...data, ...response, name: (data.pname || data.name)};
        },

        async checkPremiumStatus({dispatch, state, commit}, {panels, favorites, presetId}) {
            let preset = panels.reduce((arr, item) => {
                if (item && item.panelItems && item.panelItems.length) {
                    arr = [...arr, ...item.panelItems];
                }

                return arr;
            }, []).find(item => item.id == presetId);

            if (!preset) {
                preset = favorites.find(item => item.id == presetId);
            }

            if (preset && preset.isPremium && !await dispatch('isPremiumCurrentUser')) {
                commit("changePremiumStatus", true);
            } else if (state.premiumStatus/*  || (!preset || !preset.isPremium) */) {
                commit("changePremiumStatus", false);
            }
        },

        setUserProjects({commit}, files) {
            commit(userFiles, files);
        },

        globalLayerPopUpParams({commit}, obj) {
            commit('globalLayerPopUpParams', obj);
        },

        confirmPopUpConfig({commit}, obj) {
            commit('confirmPopUpConfig', obj);
        },

        confirmPopUpAnswer({commit}, obj) {
            commit('confirmPopUpAnswer', obj);
        },

        popupGraphicsData({commit}, data) {
            commit('popupGraphicsData', data);
        },

        textPanelUpdate({commit}) {
            commit('textPanelUpdate');
        },

        zoomDashboard({commit}, zoom) {
            commit('zoomDashboard', zoom);
        },

        favorites({commit}, data) {
            commit('favorites', data);
        },

        showLayerProperties(context, isShow) {
            context.commit('showLayerProperties', isShow);
        },

        preloader(context, obj) {
            context.commit('preloader', obj);
        },

        isUpdateCache(context) {
            context.commit('isUpdateCache');
        },

        setUserFiles({commit}, files) {
            commit(userFiles, files);
        },

        resetCanvas({commit}, resetCanvas) {
            commit('resetCanvas', resetCanvas);
        },

        fabricSlide({commit}, isHide) {
            commit('fabricSlide', isHide);
        },

        isInitialLib({commit}, status) {
            commit('isInitialLib', status);
        },

        userFile(context, value) {
            context.commit('userFile', value)
            return true;
        },

        selectedColor(context, value) {
            context.commit('selectedColor', value)
        },

        popupsToggleStatus(context, value) {
            context.commit('popupsToggleStatus', value)
        },

        sliderRangeStateChange(context, amount) {
            context.commit('sliderRangeStateChange', amount)
        },
        addSliderRangeState(context, amount) {
            context.commit('addSliderRangeState', amount)
        },
        settingsToggle(context, propertyObj) {
            context.commit('settingsToggleState', propertyObj)
        },
        sidebarPanelToggle(context, panel) {
            context.commit('sidebarPanelState', panel)
        },
        footerToggle(context, value) {
            context.commit('footerState', value)
        },
        zoomToggle(context, value) {
            context.commit('zoomState', value)
        },
        layersToggle(context) {
            context.commit('layersState');
        },
        popupsToggle(context, propertyObj) {
            context.commit('popupsToggleState', propertyObj)
        },


        currentFont(context, value) {
            context.commit('currentFont', value)
        },

        USER(context, USER) {
            context.commit('USER', USER);
        },

        async ADINFO(context) {
            let adInfo = {}
            const data = await fetch(context.state.prefixURL + '/get-ads-detail').then(res => res.json());
            if(data.success){
                adInfo = data.data;
            }
            context.commit('ADINFO', adInfo);
        },

        async INFO(context) {
            let info = {}
            const data = await fetch(context.state.prefixURL + '/get-info').then(res => res.json());
            if(data.success){
                info = data.data;
            }
            context.commit('INFO', info);
        },

        changesApplied(context) {
            context.commit('popupsToggleState', {
                property: 'changesApplied', value: true
            });

            setTimeout(() => {
                context.commit('popupsToggleState', {
                    property: 'changesApplied', value: false
                });
            }, 1000);
        },
        textElementStateToggle(context, value) {
            context.commit('textElementState', value);
        },
    }
});
