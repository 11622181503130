import _ from 'lodash';
import LockZoom from './LockZoom';

export default {
    created() {
        window.a = this;
    },

    mixins: [LockZoom],
    data() {
        const eraseConfigDef = {
            brushSize: 10,
            brushSoftness: 0,
            brushIntensity: 100,
            showMask: false,
            invertSelection: false,
            tool: 'brush'
        };

        return {
            eraseData: JSON.parse(JSON.stringify(eraseConfigDef)),
            eraseConfigDef, maskTexture: {},
            canvasSize: this.$lib.app._canvasSize
        };
    },

    computed: {
        minCanvasSize() {
            return Math.min(this.canvasSize.w, this.canvasSize.h) * .9;
        },

        zoom() {
            return this.$store.state.global.zoom;
        }
    },

    watch: {
        zoom(value) {
            console.log('update zooom');
            this.updateCanvasSizeByZoom();
        }
    },

    methods: {
        applyMixin() {
            _LIB.Drawing.canvas.freeDrawingBrush.resizeTo(
                _LIB.app._canvasSize.w,
                _LIB.app._canvasSize.h,
                true
            );
        },

        updateGlobalEarseConfig() {
            this.$store.state.earseConfig = this.eraseData;
        },

        changeConfig({ key, value }, isSetup = false) {
            this.eraseData[key] = value;

            switch (key) {
                case 'brushSize': {
                    this.eraseData._size = this.minCanvasSize * ((value / 100) || 1);
                    break;
                }
            }

            const cloneData = _.cloneDeep({
                ...this.eraseData,
                brushSoftness: _LIB.lib.conv(this.eraseData.brushSoftness, 1, 30)
            });

            this.$lib.Drawing.setupDrawingParams(cloneData);
            this.updateGlobalEarseConfig();

            if (['showMask', 'invertSelection'].includes(key)) { this.setupEffect(); }
            if (key == 'tool') { this.$lib.Drawing.setupTool(value == 'erase'); }

            if (isSetup) this.setupEffect();
        },

        setupEffect() {
            console.log('setupEffect');
        },

        getFilterByContainer(container) {
            return container.filters.find(filter => {
                try {
                    return filter.__proto__.constructor.name == 'MaskFilter';
                } catch (err) {
                    return false;
                }
            });
        },

        selectPointerEvents(isLock = false) {
            if (isLock) {
                $('#drawingCanvas').closest('.centered-content').css({ 'pointer-events': 'none' });
            } else {
                $('#drawingCanvas').closest('.centered-content').css({ 'pointer-events': 'all' });
            }
        },

        updateMask: _.debounce(function (container, data, w, h) {
            const filter = this.getFilterByContainer(container);
            if (!filter) return console.error('ERRR FILTER');

            filter.textureConfig.texture.baseTexture.setRealSize(w, h);
            filter.textureConfig.texture.baseTexture.resource.data = data;
            this.$lib.app.reRenderCanvas();
        }, 10),

        exportDraw() {
            const localCanvas = this.$lib.Drawing.canvas.freeDrawingBrush.localCanvas;
            const w = localCanvas.width;
            const h = localCanvas.height;

            const context = this.$lib.Drawing.canvas.freeDrawingBrush.context;
            const data = context.getImageData(0, 0, w, h);

            // const data = {
            //     width: 1,
            //     height: 1,
            //     data: new Uint8Array([1, 1, 1, 1]),
            // };
            return data;
        },

        async setMaskTexture(data, visible = false, setup = false) {
            this.maskTexture = {
                height: data.height,
                width: data.width,
                visible, data: data.data
            };

            if (setup) { await this.setupEffect(); }
        },

        resetDrawing(visible = false, reRender = false) {
            if (!visible) this.$store.state.drawEventVisible = false;
            this.$lib.Drawing.clearDrawing(reRender);
            this.$lib.Drawing.setupTool(false);
            this.eraseData = JSON.parse(JSON.stringify(this.eraseConfigDef));

            this.changeConfig({ key: 'brushSize', value: this.eraseData.brushSize }, reRender);
        },
    }
};
